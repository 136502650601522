<header>Ticket kaufen</header>

<div class="main-wrapper container-fluid">

  <strong class="loader-message" *ngIf="showLoader">Bitte warten, während wir den QR-Code vorbereiten</strong>
  <app-waiter *ngIf="showLoader"></app-waiter>

  <div style="display: block;" class="vh-73" *ngIf="showQrCode">
    <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
      <div class="card border-0" style="background-color: #00000000;">
        <app-showqrcode class="qr-code" [verificationUrl]="appUrl"></app-showqrcode>
      </div>
    </div>
  </div>

</div>
<app-dialog-handler class="dialog-handler" *ngIf="blnShowDialogHandler && messageType!==undefined" [messageType]="messageType" (CancelAction)="cancelAction()" (OkAction)="okAction()" [headingMessageText]="alertMessage" [additionalMessageText]="hintMessage"></app-dialog-handler>