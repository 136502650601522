import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/ui/home/home.component';
import { ShowQrCodeComponent } from './components/ui/showqrcode/showqrcode.component';
import { VDVTicketComponent } from './components/credentials/vdv-ticket/vdv-ticket.component';
import { VDVTicketFavouritesComponent } from './components/credentials/vdv-ticket-favourites/vdv-ticket-favourites.component';
import { WohngeldbescheidComponent } from './components/credentials/wohngeldbescheid/wohngeldbescheid.component';
import { DefaultCredentialComponent } from './components/credentials/besuchernachweis/besuchernachweis.component';
import { PartySelectorComponent } from './components/ui/party-selector/party-selector.component';
import {PrivacyPoliceComponent} from './components/ui/privacy-police/privacy-police.component';
import {TermsConditionsComponent} from './components/ui/terms-conditions/terms-conditions.component';
import {SocialPassComponent} from './components/credentials/socialpass/socialpass.component';
import {BesuchernachweisVerificationComponent} from './components/credentials/besuchernachweis-verification/besuchernachweis-verification.component';
import { SEPADirectDebitMandateComponent } from './components/credentials/sepa-direct-debit-mandate/sepa-direct-debit-mandate.component';
import { BadaComponent } from './components/credentials/bada/bada.component';
import { BadaLoginComponent } from './components/credentials/bada-login/bada-login.component';
import { KommpassComponent } from './components/credentials/kommpass/kommpass.component';
import { NextbikeComponent } from './components/credentials/nextbike/nextbike.component';
import { JungheinrichComponent } from './components/credentials/jungheinrich/jungheinrich.component';
import { TeilautoComponent } from './components/credentials/teilauto/teilauto.component';
import { DrivingLicenseComponent } from './components/credentials/driving-license/driving-license.component';
import { MobilePhoneContractComponent } from './components/credentials/mobile-phone-contract/mobile-phone-contract.component';
import { MobilePhoneContractLoginComponent } from './components/credentials/mobile-phone-contract-login/mobile-phone-contract-login.component';
import { Openid4vpLoginComponent } from './components/credentials/openid4vp-login/openid4vp-login.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent, data: { title: 'Hauptseite' } },
  { path: 'ticketshop', component: VDVTicketFavouritesComponent, data: { title: 'Ticketverkauf' }, },
  { path: 'ticketselection/:id', component: VDVTicketComponent, data: { title: 'Ticketverkauf' }, },
  { path: 'qrcode/:id', component: ShowQrCodeComponent, data: { title: 'Nachweis Herunterladen' }, },
  { path: 'kommpass', component: KommpassComponent, data: { title: 'Kommunaler Pass' }, },
  { path: 'wohngeldbescheid', component: WohngeldbescheidComponent, data: { title: 'Wohngeldbescheid' } },
  { path: 'besuchernachweis', component: DefaultCredentialComponent, data: { title: 'Besuchernachweis' } },
  { path: 'besuchernachweis/:credentialStartType', component: DefaultCredentialComponent, data: { title: 'Besuchernachweis' } },
  { path: 'party-selector', component: PartySelectorComponent, data: { title: 'Standauswahl' } },
  { path: 'party-selector/:credentialStartType', component: PartySelectorComponent, data: { title: 'Standauswahl' } },
  { path: 'pp', component: PrivacyPoliceComponent, data: { title: 'Privacy Policy' } },
  { path: 'tc', component: TermsConditionsComponent, data: { title: 'Terms & Conditions' } },
  { path: 'ddp', component: SocialPassComponent, data: { title: 'Sozialpass' } },
  { path: 'sccv', component: BesuchernachweisVerificationComponent, data: { title: 'SCC Besuchernachweis Validierung' } },
  { path: 'txcpp/de', component: PrivacyPoliceComponent, data: { pathString: 'txcpp/de', title: 'TicketXchange Datenschutzbestimmungen'}},
  { path: 'txcpp/en', component: PrivacyPoliceComponent, data: { pathString: 'txcpp/en', title: 'TicketXchange Privacy Policy'}},
  { path: 'sepamandate', component: SEPADirectDebitMandateComponent, data: { title: 'Erteilung des SEPA-Lastschriftmandats'}},
  { path: 'bada', component: BadaComponent, data: { title: 'Eröffnung eines Bankkontos'}},
  { path: 'badalogin', component: BadaLoginComponent, data: { title: 'Ihr Bankkonto'}},
  { path: 'nextbike', component: NextbikeComponent, data: { title: 'Ihr Nextbike-Nutzungsvertrag'}},
  { path: 'jungheinrich', component: JungheinrichComponent, data: { title: 'Ihr Jungheinrich-Nutzungsvertrag'}},
  { path: 'teilauto', component: TeilautoComponent, data: { title: 'Ihr teilAuto-Nutzungsvertrag'}},
  { path: 'drivinglicense', component: DrivingLicenseComponent, data: { title: 'Ausstellung einer Fahrerlaubnis'}},
  { path: 'mobilecontract', component: MobilePhoneContractComponent, data: { title: 'Ihr Mobilfunkvertrag'}},
  { path: 'mobilecontractlogin', component: MobilePhoneContractLoginComponent, data: { title: 'In Ihrem Kundenkonto anmelden'}},
  { path: 'openid4vplogin', component: Openid4vpLoginComponent, data: { title: 'In Ihrem OpenID4 anmelden'}},
  { path: '**', /* 404 */ redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
