import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EvidenceModel } from 'src/app/models/w3c/vcdm/evidence.model';
import { CreateMessageService } from 'src/app/services/create-message.service';
import { CredentialBuilderService } from 'src/app/services/credential-builder.service';
import { DataService } from 'src/app/services/data.service';
import { LocalDataStorageService } from 'src/app/services/local-data-storage.service';
import { environment } from 'src/environments/environment';
import { MobileContractCredenial } from 'src/app/models/kaprion/vc/mobile-contract.model';
import { VerifiableCredential1xModel } from 'src/app/models/w3c/vcdm/verifiable-credential-1x.model';
import { Issuer } from 'src/app/models/issuer.model';
import { MessageContainer } from 'src/app/models/messages/messageContainer.model';
import { GetLangPipe } from 'src/app/pipe/get-lang.pipe';
import { PurposeGeneratorService } from 'src/app/services/purpose-generator.service';
import { CredentialSanityCheckService } from 'src/app/services/credential-sanity-check.service';
import { MessageType } from 'src/app/components/ui/dialog-handler/dialog-handler.component';
import { Inputdescriptor } from 'src/app/models/inputdescriptor.model';
import { ValidationRequestPresentationModel } from 'src/app/models/messages/validation-request-presentation.model';


@Component({
  selector: 'app-mobile-phone-contract',
  templateUrl: './mobile-phone-contract.component.html',
  styleUrls: ['./mobile-phone-contract.component.scss'],
  providers: [GetLangPipe]
})
export class MobilePhoneContractComponent implements OnInit {

  messageText: string = "";
  proofArray: string[] = [];
  sessionId: string = "";
  oobid: string = "";
  credentialArray: any[] = [];
  evidence: EvidenceModel[] = [];
  showQrCode = false;
  buttonText: string = "Weiter";
  credentialSubjects: { [key: string]: any }[] = [];
  appUrl: string = ""
  isRequestPresentation: boolean = true;
  credSubject: MobileContractCredenial;
  requestPresentation: any[] = ["KommPassPerson", "KommPassAdresse", "KommPassGeburtsurkunde", "BADA"];
  statusOk: boolean = false;
  invalidProperty: string[] = [];
  alertMessage: string = "";
  hintMessage: string = "";
  isIssuance: boolean = false;
  displayObject: { [key: string]: any } = {}
  occurances: any[] = [];
  presentationID: string = "";
  generatedCredential: VerifiableCredential1xModel | null = null;
  canLogin: boolean = false;
  steps: number = 0;
  imgURL = environment.imgURL;
  readonly credentialType: string = "MobilePhoneContract";
  purposeObjectArray: any[] = [];

  blnShowDialogHandler: boolean = false;

  messageType: MessageType | undefined = undefined;

  messageArray: string[] = []

  constructor(private dataService: DataService,
    private createMessageService: CreateMessageService,
    private localDataStorageService: LocalDataStorageService,
    private router: Router,
    private csb: CredentialBuilderService,
    private pgs: PurposeGeneratorService,
    private cscService: CredentialSanityCheckService) {
    this.credSubject = {
      id: "iss:REPLACE_ME_HOLDER",
      customerId: "",
      brand: "Mini",
      price: 9.99,
      phoneNumber: '0177/12345678', //valid Iban
      isProtectedByCRE: true,
    };
  }

  ngOnInit(): void {
    this.sessionId = crypto.randomUUID();
    this.isIssuance = false;
    this.localDataStorageService.setData("sessionId", this.sessionId);
    let inputDescArr: any[] = [];
    this.requestPresentation.forEach(entry => {
      let inputDesc: any = new Inputdescriptor(entry, this.credentialType)
      inputDesc = inputDesc.toJSON();
      if (ValidationRequestPresentationModel.isInputDescriptorObject(inputDesc)) {
        inputDescArr.push(inputDesc)
      } else {
        this.messageType = MessageType.BackDialog;
        this.alertMessage = "Es liegt ein Fehler beim Erstellen der Nachricht vor."
        this.hintMessage = "Es konnte kein gültiges Objekt für den InputDescriptor gebildet werden.  Bitte kontaktieren Sie den Support."
        this.blnShowDialogHandler = true;
      }

    })
    this.requestPresentation = inputDescArr;
  }

  selectTarif(brand: number) {
    if (brand === 1) {
      this.credSubject.brand = "Mini";
      this.credSubject.price = 9.99;
      //this.credSubject.datavolume = "5 GB";
    } else if (brand === 2) {
      this.credSubject.brand = "Comfort";
      this.credSubject.price = 19.99;
      //this.credSubject.datavolume = "10 GB";
    } else if (brand === 3) {
      this.credSubject.brand = "Premium";
      this.credSubject.price = 29.99;
      //this.credSubject.datavolume = "15 GB";
    }
    this.steps++;
  }

  reset(): void {
    this.sessionId = this.localDataStorageService.getData("sessionId");
    this.buttonText = "Weiter";
    this.credentialArray = [];
    this.appUrl = "";
    this.showQrCode = false;
    this.proofArray = [];
    this.evidence = [{
      docId: '',
      documentPresence: 'Digital',
      evidenceDocument: '',
      id: environment['HOME_URL'] + '/PhotoVerification/',
      subjectPresence: 'Physical',
      type: ['DocumentVerification'],
      verifier: 'KAPRION Demo Shop User'
    }]
  }

  onSubmit(): void {
    if (this.steps < 1) {
      this.buttonText = "Weiter";
    } else if (this.steps === 1) {
      this.buttonText = "Konto eröffnen";
    } else {
      if (this.proofArray.length !== this.requestPresentation.length && this.steps === 2) {
        this.issueCredential();
      }
    }
    this.steps++;
  }

  /**
     * 
     * @param requestedItem item (type of credential) to check against requested types for presentation
     * @returns 
     */
  checkRequestedItem(requestedItem: string) {
    return this.requestPresentation.includes(requestedItem);
  }

  issueCredential() {
    this.isRequestPresentation = true;
    this.isIssuance = false;
    let credentialContext = environment['DC_URL'] + "/credentialSubject/v7";
    let id = environment['HOME_URL'] + "/samples/KAPRION/" + this.credentialType + "/" + crypto.randomUUID();
    this.localDataStorageService.setData("taskType", "cs.icp.split.offerCredential");
    this.localDataStorageService.setData("requestPresentation", this.requestPresentation);
    //this.expandRequestPresentationByPurpose();
    this.localDataStorageService.setData("purposeObjectArray", this.purposeObjectArray)
    let credential = this.csb
      .id(id)
      .addContext(credentialContext)
      .addType(this.credentialType)
      .issuanceDate()
      .expirationDate(this.csb.getDateTime(true, 1))
      .credentialSubject(this.credSubject)
      .evidence(this.evidence)
      .build();
    this.credentialArray.push(credential);
    this.localDataStorageService.setData("credentialArray", this.credentialArray);
    let message = this.createMessageService.prepareMessageShortenedVersion(Issuer.getMobileIssuer(), true, true, false).then(msg => {
      this.appUrl = environment['APP_URL'] + "/issue" +
        "?_oobid=" + this.localDataStorageService.getData("oobid") +
        "&credentialProviderLink=" + environment['DC_URL'] + "/issuance";
      this.showQrCode = true;
      if (msg instanceof MessageContainer) {
        msg = JSON.stringify(msg, (key, value) => {
          if (value === undefined || value === null) {
            return undefined;
          }
          if (Array.isArray(value)) {
            return value.filter((item) => item !== null && item !== undefined);
          }
          return value;
        });
        this.dataService.send(msg, environment['DC_URL'] + '/v2/initSession', true).then((response: any) => {
          if (response['type'] === "de.kaprion.icp.s2p.issueCredential.req") {
            this.statusOk = true;
            let attach = response['credentialApplication']['verifiableCredential'];

            attach.forEach((credential: any) => {
              this.processReceivedVC(<VerifiableCredential1xModel>credential);
            });

            if (this.proofArray.length >= this.requestPresentation.length) {
              this.isIssuance = true;
              this.displayObject = <Object>this.credSubject;
              this.messageText = "Ihr Mobilfunkvertrag wird ausgestellt."
              let credential = this.csb
                .id(id)
                .addContext(credentialContext)
                .addType(this.credentialType)
                .issuanceDate()
                .expirationDate(this.csb.getDateTime(true, 1))
                .credentialSubject(this.credSubject)
                .evidence(this.evidence)
                .build();
              this.localDataStorageService.setData("credentialArray", [credential]);
              this.localDataStorageService.setData("taskType", "cs.icp.split.issueCredential");
              this.showQrCode = false;
              let finalMessage = this.createMessageService.prepareMessageShortenedVersion(Issuer.getMobileIssuer(), true, true, false).then(finalMsg => {
                if (finalMsg instanceof MessageContainer) {
                  finalMsg = JSON.stringify(finalMsg, (key, value) => {
                    if (value === undefined || value === null) {
                      return undefined;
                    }
                    // Filtering null and undefined values from arrays
                    if (Array.isArray(value)) {
                      return value.filter((item) => item !== null && item !== undefined);
                    }
                    return value;
                  });
                  this.dataService.send(finalMsg, environment['DC_URL'] + '/v2/initSession').then((response: any) => {
                    if (response['success']) {
                      this.messageText = 'Ihr Nachweis wurde erfolgreich ausgestellt.'
                      this.messageArray.push(this.messageText);
                      this.statusOk = true;
                      this.isIssuance = false;
                      if (credential.type.includes("SEPADirectDebitMandate"))
                        this.generatedCredential = credential;
                      //this.buttonText = "Anmelden";
                      this.messageArray.push('Ihr Mobilfunkvertrag wurde erfolgreich angelegt')
                    } else {
                      this.messageText = "Nachweise konnten nicht verarbeitet werden: Status " + response['details']
                      this.messageArray.push(this.messageText);
                      this.statusOk = false;
                    }
                  });
                }
              })
            } else {
              this.messageText = "Vorgelegte Nachweise erfüllen nicht die Anforderung zur Ausstellung des Mobilfunkvertrags-Credentials."
              this.messageArray.push(this.messageText);
              this.hintMessage = "Folgende Nachweise erfüllen nicht die Anforderungen: " + this.checkEntry()
              this.statusOk = false;
            }
          } else {
            this.messageText = "Nachweise konnten nicht verarbeitet werden: Status " + response['details']
            this.messageArray.push(this.messageText);
            this.statusOk = false;
          }
          this.credentialArray = [];
          // alert(response) // TODO Updatet for better Message -> Dialog Box
          this.reset();
        }).catch((e) => {

          console.warn("Rejected: " + e);
        });
      }
    });
  }

  /**
     * 
     * @returns entry that is not present in the result array of received credentials from presentation
     */
  checkEntry() {
    return this.requestPresentation.filter(entry => !this.proofArray.includes(entry));
  }

  processReceivedVC(credential: VerifiableCredential1xModel) {
    let vcType = this.cscService.evaluateForm(credential, this.requestPresentation);
    if (vcType.length > 0) {
      let evidence: EvidenceModel = {
        docId: (' ' + credential.id).slice(1),
        documentPresence: 'Digital',
        evidenceDocument: vcType,
        id: '__HOME_URL__/Verification/' + crypto.randomUUID(),
        subjectPresence: 'Physical',
        type: ['DocumentVerification'],
        verifier: 'https://demo.shop.it.kaprion.net/samples/KAPRION/SysActor/8506cbb4-c8d0-4b34-8f93-c5638893f0e2'
      };
      this.proofArray.push(vcType);
      this.evidence.push(evidence);
    }
  }

  expandRequestPresentationByPurpose() {
    this.requestPresentation.forEach((cType: string, index: number) => {
      let purposeString: string = ""
      if (cType.includes('KommPass')) {
        purposeString = 'Wir benötigen Ihre ' + cType.split('KommPass')[1] + '-Informationen, um den Nutzungsvertrag mit Ihnen abschließen zu können.'
      } else if (cType.includes('KDK')) {
        purposeString = 'Wir benötigen Ihre ' + new GetLangPipe().transform((cType.split('KDK')[1].split('K')[0])) + '-Informationen, um den Nutzungsvertrag mit Ihnen abschließen zu können.'
      } else {
        purposeString = 'Ihre Bankverbindung wird benötigt, um Ihnen ein Fahrzeug auszuleihen';
      }
      let obj = {
        [cType]: {
          'purpose': purposeString
        }
      }
      this.purposeObjectArray.push(obj);
      this.localDataStorageService.setData("purposeObjectArray", this.purposeObjectArray)
    });
  }

  //abort-functionality | back-button
  onClickBack() {
    this.buttonText = "Konto eröffnen";
  }

  onClickCancel() {
    this.messageType = MessageType.OkCancelDialog;
    this.alertMessage = "Möchten Sie zur Credentialauswahl zurückkehren?";
    this.hintMessage = "Ihre Eingaben gehen dabei verloren.";
    this.blnShowDialogHandler = true;
  }
  /**
 * catches emitter from dialog-handler
 */
  cancelAction() {
    this.blnShowDialogHandler = false;
  }

  /**
 * catches emitter from dialog-handler
 */
  okAction() {
    this.blnShowDialogHandler = false;
  }

  // to remove chosen item and go back to tarif selection
  removeFromBasket() {
    this.steps = 0;
  }
}
