import { Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})

export class ToasterComponent implements OnInit, OnChanges {

  @Input() messageText: string = "";
  @Input() status: boolean = false;

  private timeoutId: any;

  constructor(private router: Router, private readonly errorHandlingService: ErrorHandlingService) { }

  ngOnInit(): void {
    this.errorHandlingService.errorMessage.subscribe((msg) => {
      this.setMessageText(msg.messageText, msg.status)
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['messageText'] && changes['messageText'].currentValue) {
      this.setMessageText(changes['messageText'].currentValue, changes['status'].currentValue)
    }
  }

  private setMessageText(msg: string, state: boolean) {
    this.messageText = msg;
    this.status = state;
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(() => {
      this.messageText = "";
      this.status = false;
      this.router.navigate(['/home'])
    }, 5000)
  }
}
