// component shows the footer buttons and action events of those buttons
import { Component, Input, SimpleChanges } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { LocalDataStorageService } from 'src/app/services/local-data-storage.service';
import {VDVTicketModel} from '../../models/kaprion/vc/vdvticket.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent  {

  imgURL = environment.imgURL;

  @Input() footerButtons: any = [];
  @Input() selectedTicket: {[key: string]: any} = {};

  vdvticket: VDVTicketModel = {
    id: 'iss:REPLACE_ME_HOLDER',
    areaOfValidity: {
      provider: '',
      area: ['']
    },
    brand: '',
    category: '',
    class: '',
    passengerType: '',
    priceLevel: 0,
    ticketToken: '',
    totalPrice: {
      price: 0,
      priceCurrency: '',
      valueAddedTaxIncluded: false
    },
    vat: '',
    isProtectedByCRE: true
  }

  constructor(private location: Location, private router: Router, private localDataStorageService: LocalDataStorageService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['footerButtons'].currentValue !== undefined
      && changes['footerButtons'].currentValue !== null) {
      this.footerButtons = changes['footerButtons'].currentValue;
    }
  }

  /**
   * to handle the footer button click events
   * @param fb
   */
  footerButtonAction(fb: string) {
    if (fb === 'Jetzt kaufen') {
        if (this.localDataStorageService.getData("sessionId").length === 0) {
          this.localDataStorageService.setData("sessionId", crypto.randomUUID());
        }
        if (this.localDataStorageService.getData("oobid").length === 0) {
          this.localDataStorageService.setData("oobid", crypto.randomUUID());
        }
        this.router.navigate(['/qrcode/' + this.localDataStorageService.getData("sessionId")]);
    } else if (fb === 'Zurück') {
      this.location.back();
    } else if (fb === 'Tickets') {
      this.router.navigate(['/ticketselection', 3]);
    }
  }
}
