import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EvidenceModel } from 'src/app/models/w3c/vcdm/evidence.model';
import { LocalDataStorageService } from 'src/app/services/local-data-storage.service';
import { BankAccountDisposalAuthorizationModel } from 'src/app/models/kaprion/vc/bada.model';
import { IbanGeneratorService } from 'src/app/services/iban-generator.service';
import { VerifiableCredential1xModel } from 'src/app/models/w3c/vcdm/verifiable-credential-1x.model';
import { map, timer, takeWhile, finalize } from 'rxjs';
import { MessageContainer } from 'src/app/models/messages/messageContainer.model';
import { Issuer } from 'src/app/models/issuer.model';
import { CreateMessageService } from 'src/app/services/create-message.service';
import { DataService } from 'src/app/services/data.service';
import { CredentialSanityCheckService } from 'src/app/services/credential-sanity-check.service';
import { environment } from 'src/environments/environment';
import { MessageType } from 'src/app/components/ui/dialog-handler/dialog-handler.component'
import { ValidationRequestPresentationModel } from 'src/app/models/messages/validation-request-presentation.model';
import { Inputdescriptor } from 'src/app/models/inputdescriptor.model';

@Component({
  selector: 'app-bada-login',
  templateUrl: './bada-login.component.html',
  styleUrls: ['./bada-login.component.scss']
})
export class BadaLoginComponent implements OnInit {

  @Input() requestedCredential: BankAccountDisposalAuthorizationModel | null = null;

  blnShowDialogHandler: boolean = false;
  loginInformation: any;
  canLogin = false;
  isLoggedIn: boolean = true;
  messageText: string = "";
  proofArray: string[] = [];
  sessionId: string = "";
  ticketIdArray: string[] = [];
  oobid: string = "";
  credentialArray: any[] = [];
  evidence: EvidenceModel[] = [];
  showQrCode = false;
  buttonText: string = "Anmelden";
  credentialSubjects: { [key: string]: any }[] = [];
  appUrl: string = ""
  isRequestPresentation: boolean = true;
  credSubject: BankAccountDisposalAuthorizationModel;
  requestPresentation: any[] = ["BADA"];
  statusOk: boolean = false;
  alertMessage: string = "";
  hintMessage: string = "";
  messageType: MessageType | undefined = undefined;
  messageArray: string[] = [];
  presentationID: string = ""


  balance: number = 0.00;
  owner: string = "";
  iban: string = "";

  loginTime: number;
  remainingTime = timer(0, 1000).pipe(
    map(n => (5 * 60 - n) * 1000),
    takeWhile(n => n >= 0),
    finalize(() => {
      this.messageText = "Sie werden nun ausgeloggt";
      this.statusOk = true;
      this.messageArray.push(this.messageText);
      setTimeout(() => {
        this.router.navigate(["/home"]);
      }, 5000)
    })
  );
  isLogoutWarning = false;
  logoutWarningTime: any;
  fragments: string[] | null = [];

  constructor(
    private localDataStorageService: LocalDataStorageService,
    private router: Router,
    private ibanGenService: IbanGeneratorService,
    private createMessageService: CreateMessageService,
    private dataService: DataService,
    private cscService: CredentialSanityCheckService) {
    this.credSubject = {
      id: "iss:REPLACE_ME_HOLDER",
      creditInstitution: "IDIdeal Bank",
      brand: "IDI comfort",
      givenName: "",
      familyName: "",
      iban: this.ibanGenService.generateIban(), //valid Iban
      bic: "",
      isProtectedByCRE: true,
    };
    this.loginInformation = this.localDataStorageService.getData("loginInformation");
    if (this.loginInformation['givenName'] === undefined
      || this.loginInformation['familyName'] === undefined
      || this.loginInformation['iban']) {
      this.owner = "Erika Mustermann";
      this.iban = this.ibanGenService.generateIban();
    } else {
      this.owner = this.loginInformation['givenName'] + " " + this.loginInformation['familyName'];
      this.iban = this.loginInformation['iban'];
    }
    const regex = new RegExp("[a-zA-Z0-9]{1,4}", "g");
    this.fragments = this.iban.match(regex) !== null ? this.iban.match(regex) : [];
    this.loginTime = Date.now();
  }

  ngOnInit(): void {
    this.isLoggedIn = false;
    this.showQrCode = true;
    this.credentialArray = ['BADA']
    this.localDataStorageService.setData("credentialArray", this.credentialArray);
    let inputDescArr: any[] = [];
    this.requestPresentation.forEach(entry => {
      let inputDesc: any = new Inputdescriptor(entry).toJSON();
      if (ValidationRequestPresentationModel.isInputDescriptorObject(inputDesc)) {
        inputDescArr.push(inputDesc)
      } else {
        this.messageType = MessageType.BackDialog;
        this.alertMessage = "Es liegt ein Fehler beim Erstellen der Nachricht vor."
        this.hintMessage = "Es konnte kein gültiges Objekt für den InputDescriptor gebildet werden.  Bitte kontaktieren Sie den Support."
        this.blnShowDialogHandler = true;
      }
    })
    this.requestPresentation = inputDescArr;
    this.genPPPMessage().then(result => {
      this.showQrCode = false;
      this.isLoggedIn = true;
    });
  }

  /**
   * prepares message for request presentation and sends request
   */
  private genPPPMessage() {
    return new Promise((resolve, reject) => {
      this.presentationID = crypto.randomUUID();
      this.localDataStorageService.setData("ticketIdArray", [this.presentationID]); // presentation_definition.id
      this.localDataStorageService.setData("requestPresentation", this.requestPresentation);
      this.localDataStorageService.setData("taskType", "de.kaprion.ppp.s2p");
      this.createMessageService.prepareMessageShortenedVersion(Issuer.getIDIBankIssuer(), true, true, false).then((msg: any) => {
        if (msg instanceof MessageContainer) {
          if (msg.getMessages().length < msg.getTaskAmount()) {
            msg.setTaskAmount(msg.getMessages().length);
          }

          msg = JSON.stringify(msg, (key, value) => {
            if (value === undefined || value === null) {
              return undefined;
            }
            // Filtering null and undefined values from arrays
            if (Array.isArray(value)) {
              return value.filter((item) => item !== null && item !== undefined);
            }
            return value;
          });
          this.appUrl = environment['APP_URL'] + "/verify" +
            "?_oobid=" + this.presentationID +
            "&credentialProviderLink=" + environment['DC_URL'] + "/validation";

          this.dataService.sendExpectJSON(msg, environment['DC_URL'] + '/initSessionValidation').then((response: any) => {
            let attach = response['attachments'][0]['data']['json']['verifiableCredential'];
            if (Array.isArray(attach)) {
              this.credentialArray = attach;
            }
            else {
              this.credentialArray.push(attach);
            }
            this.credentialArray.forEach(credential => {
              if ((<VerifiableCredential1xModel>credential).type.includes("BADA")) {
                this.processReceivedVC(<VerifiableCredential1xModel>credential);
              }
            });
            resolve(this.credentialArray);
          }).catch((e: any) => {
            console.warn("Rejected: " + e);
            reject("Something went wrong: " + e)
          });
        }
      });
      this.appUrl = environment['APP_URL'] + "/verify" +
        "?_oobid=" + this.presentationID +
        "&credentialProviderLink=" + environment['DC_URL'] + "/validation";
      this.showQrCode = true;
      this.isRequestPresentation = true;

    })
  }

  /**
     * 
     * @param requestedItem item (type of credential) to check against requested types for presentation
     * @returns 
     */
  checkRequestedItem(requestedItem: string) {
    return this.requestPresentation.includes(requestedItem);
  }

  /**
     * 
     * @returns entry that is not present in the result array of received credentials from presentation
     */
  checkEntry() {
    return this.requestPresentation.filter(entry => !this.proofArray.includes(entry));
  }

  /**
 * Sanitycheck for credential and add it to evidence when checked
 * @param credential credential to be checked and add as evidence
 */
  processReceivedVC(credential: VerifiableCredential1xModel) {
    let vcType = this.cscService.evaluateForm(credential, this.requestPresentation);
    if (vcType.length > 0) {
      let evidence: EvidenceModel = {
        docId: (' ' + credential.id).slice(1),
        documentPresence: 'Digital',
        evidenceDocument: vcType,
        id: '__HOME_URL__/Verification/' + crypto.randomUUID(),
        subjectPresence: 'Physical',
        type: ['DocumentVerification'],
        verifier: environment['HOME_URL'] + '/samples/KAPRION/SysActor/8506cbb4-c8d0-4b34-8f93-c5638893f0e2'
      };
      this.proofArray.push(vcType);
      this.evidence.push(evidence);
      this.canLogin = true;
    }
  }

  //abort-functionality | back-button
  onClickBack() {
    this.buttonText = "Konto eröffnen";
  }

  // cancel-action: show warning onClickCancel
  onClickCancel() {
    this.messageType = MessageType.OkCancelDialog
    this.alertMessage = "Möchten Sie zur Credentialauswahl zurückkehren?"
    this.hintMessage = "";
    this.blnShowDialogHandler = true;
  }
  /**
 * catches emitter from dialog-handler
 */
  cancelAction() {
    this.blnShowDialogHandler = false;
  }

  /**
 * catches emitter from dialog-handler
 */
  okAction() {
    this.blnShowDialogHandler = false;
  }
}
