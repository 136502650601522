import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CredentialManifest } from '../models/credential-manifest.model';
import { Issuer } from '../models/issuer.model';
import { IssuanceIssueCredential, IssuanceIssueCredentialShortened } from '../models/messages/issuance-issue-credential.model';
import { IssuanceOfferCredential } from '../models/messages/issuance-offer-credential.model';
import { MessageContainer } from '../models/messages/messageContainer.model';
import { DataService } from './data.service';
import { LocalDataStorageService } from './local-data-storage.service';
import { WebsocketService } from './websocket.service';
import { ValidationRequestPresentationModel } from '../models/messages/validation-request-presentation.model';
import { IssuanceOfferCredentialShort } from '../models/messages/issuance-offer-credential-short.model';

@Injectable({
  providedIn: 'root'
})
export class CreateMessageService {
  websocketMessage: { [key: string]: any } = {};
  messageState: number = 0;
  message: { [key: string]: any } = {};
  credentialArray: any[] = [];
  isOk: boolean = false;
  inMessageError: boolean = false;
  ldDoc: { [key: string]: any } = {};
  outputDesc: { [key: string]: any } = {};
  schema: { [key: string]: any } = {};
  inputDesc: { [key: string]: any } = {};
  requestPresentations: string[] = [];


  constructor(private localDataStorageService: LocalDataStorageService, private dataService: DataService, private websocketService: WebsocketService) { }

  /**
   * retrieve the ld-doc from backend
   * @returns ld-doc as object
   */
  getLdDoc() {
    return new Promise<Object>((resolve, reject) => {
      let url = environment['DC_URL'] + "/credentialSubject/v7"
      //debug url
      //let url = "http://localhost:4200/assets" + "/credentialSubject/v7/id-ideal-ld-doc-v7.json"
      this.ldDoc = this.dataService.retrieveFile(url).then(result => {
        if (result instanceof Object) {
          let ldDocument = {
            id: url,
            doc: result
          }
          resolve(ldDocument);
        } else {
          reject('wrong type: ' + typeof result);
        }
      });
    });
  }

  /**
   * retrieve the required inputdescriptors
   * @param typeArray array of credentialTypes to be requested for presentation
   * @returns array of inputdescriptors as objects
   */
  getInputDescriptors(typeArray: any[]) {
    return new Promise<Object[]>((resolve, reject) => {
      let inputDescArr: { [key: string]: any }[] = []
      for (let i = 0; i < typeArray.length; i++) {
        if (typeArray[i] !== undefined) {
          inputDescArr.push(this.dataService.retrieveFile(window.origin + "/assets/inputdescriptors/v1/" + typeArray[i] + '.json'))
        }
      }
      Promise.all(inputDescArr).then(resultArr => {
        resolve(resultArr);
      })
    })
  }

  /**
   * retrieve the required outputdescriptors
   * @param credentialIndex index of respective credential to for which to get outputdescriptor
   * @param outputdescriptorFragment fragment that specifies subtype of outputdescriptor (requires leading character by which type and subtype are identifyable. For example: "." or "-")
   * @returns outputdescriptor as object
   */
  getOutputDescriptors(credentialIndex: number, outputdescriptorFragment?: string) {
    return new Promise<Object>((resolve, reject) => {
      let outputDescUrl = window.origin + "/assets/outputdescriptors/v1/";
      if (this.credentialArray[credentialIndex]['type'] !== undefined) {
        outputDescUrl += this.credentialArray[credentialIndex]['type'][1] + outputdescriptorFragment + ".json";
      } else {
        outputDescUrl += this.credentialArray[credentialIndex] + outputdescriptorFragment + ".json";
      }
      this.outputDesc = this.dataService.retrieveFile(outputDescUrl).then(out => {
        if (out instanceof Object) {
          this.getSchemaFiles(credentialIndex).then(schemas => {
            resolve({ 'out': out, 'schemas': schemas })
          });
        } else {
          reject('wrong type: ' + typeof out);
        }

        //});
      });
    });
  }

  /**
   * 
   * @param inputDescArray array containing inputdescriptorobjects to manipulate
   */
  replacePurposeOnInputDescriptors(requestPresentation: string[], purposeObjArray: { [key: string]: any }[]) {
    let resultArr: {[key: string]: any}[] = [];
    purposeObjArray.forEach(purposeObj => {
      requestPresentation.forEach(inputDescType => {
        if (typeof purposeObj === 'object' && purposeObj[inputDescType] !== undefined) {
          if (purposeObj[inputDescType]['purpose'] !== undefined && purposeObj[inputDescType]['purpose'].length > 0) {
            let inputDescriptorObject = {
              type: inputDescType,
              purpose: purposeObj[inputDescType]['purpose']
            }
            resultArr.push(inputDescriptorObject)
          }
        }
      })
    })
    return resultArr;
  }

  /**
   * retrieve schema-file for respective credential to issue
   * @param credentialIndex index of respective credential to for which to get schema
   * @returns schema as object
   */
  getSchemaFiles(credentialIndex: number) {
    return new Promise((resolve, reject) => {
      let schemaUrl = window.origin + "/assets/schema/v1/";
      if (this.credentialArray[credentialIndex]['type'] !== undefined) {
        schemaUrl += this.credentialArray[credentialIndex]['type'][1].split('.')[0] + ".json";
      } else {
        schemaUrl += this.credentialArray[credentialIndex] + ".json";
      }
      this.schema = this.dataService.retrieveFile(schemaUrl).then(schema => {
        if (schema instanceof Object) {
          resolve(schema);
        } else {
          reject("wrong type: " + typeof schema);
        }
      });
    });
  }

  /**
   * builds message with all required configs, no lookup in database necessary
   * USE CASES: KDK, SCC-issuance ==> obsolete use-cases
   * @param issuer issuer-object specifying the issuer-entity
   * @param useCRE is hardware encrypted
   * @param isPaid have been paid
   * @param isFree is free of charge
   * @param ldUrl string of url, where ld-doc to fetch
   * @param manifest instance of Manifest for credential to issue
   * @param outputdescriptorFragment fragment that specifies subtype of outputdescriptor (requires leading character by which type and subtype are identifyable. For example: "." or "-")
   * @returns complete messagecontainer which to send to backend
   */
  prepareMessageLongWithConfigs(issuer: Issuer, useCRE: boolean, isPaid: boolean, isFree: boolean, ldUrl?: string, manifest?: CredentialManifest, outputdescriptorFragment = "") {
    return new Promise((resolve, reject) => {
      this.credentialArray = this.localDataStorageService.getData("credentialArray");
      this.requestPresentations = this.localDataStorageService.getData("requestPresentation")
      let promiseArray: Promise<any>[] = []
      let sessionId = this.localDataStorageService.getData("sessionId");
      let oobid = crypto.randomUUID();
      this.localDataStorageService.setData("oobid", oobid);
      let taskType = this.localDataStorageService.getData("taskType");
      let messageContainer = new MessageContainer(sessionId, oobid, taskType, this.credentialArray.length);
      let counter = 0;
      if ((taskType === 'de.kaprion.icp.s2p')) {
        this.getLdDoc().then(config => {
          for (let i = 1; i <= this.credentialArray.length; i++) {
            let messageObject = {};
            promiseArray[counter] = new Promise((resolve) => {
              resolve(
                this.getOutputDescriptors(i - 1, outputdescriptorFragment).then((configs: any) => {
                  new Promise((resolve) => {
                    if (!ldUrl) { ldUrl = environment['DC_URL'] + "/credentialSubject/v7"; }
                    if ((manifest === null || typeof manifest === "undefined") && outputdescriptorFragment.length === 0) {
                      manifest = new CredentialManifest("0.1.0", issuer, [configs.out], crypto.randomUUID(), []);
                    } else {
                      let idealIssuer = Issuer.getIDIdealIssuer();
                      manifest = new CredentialManifest("0.1.0", idealIssuer, [configs.out], crypto.randomUUID(), []);
                    }
                    let issuanceOfferCred = new IssuanceOfferCredential(oobid, "de.kaprion.icp.s2p", manifest, [this.credentialArray[i - 1]], [config], [configs.schemas as any], isPaid, isFree);
                    let issuanceIssueCred = new IssuanceIssueCredential(oobid, "de.kaprion.icp.s2p", [this.credentialArray[i - 1]], useCRE, undefined, "1.0");

                    Object.defineProperty(messageObject, "issuanceOfferCredential", {
                      enumerable: true,
                      configurable: false,
                      writable: true,
                      value: issuanceOfferCred.toJSON()
                    });
                    Object.defineProperty(messageObject, "issuanceIssueCredential", {
                      enumerable: true,
                      configurable: false,
                      writable: true,
                      value: issuanceIssueCred.toJSON()
                    });
                    messageContainer.setMessageObject(messageObject);
                    resolve(messageContainer)
                  })
                })
              )
            });
            counter++;
          }
          //intermediate bugfixing for not properly stored messages
          this.getAllPromises(promiseArray, messageContainer).then(finalCheck => {
            if (messageContainer.getMessages().length < messageContainer.getTaskAmount()) {
              messageContainer.setTaskAmount(this.credentialArray.length);
            }
            resolve(messageContainer);
          });
        });
      } else if (taskType === 'de.kaprion.ppp.s2p') {
        let messageObject = {};
        let nonce = this.localDataStorageService.getData('ticketIdArray')[0];
        let request = new ValidationRequestPresentationModel(oobid, nonce, this.requestPresentations);
        Object.defineProperty(messageObject, 'requestPresentation', {
          enumerable: true, configurable: false, writable: true, value: request.toJSON()
        });

        messageContainer.setMessageObject(messageObject);
        console.warn(messageContainer.getMessages())
        //if (messageContainer.getMessages().length < messageContainer.getTaskAmount()) {
        messageContainer.setTaskAmount(messageContainer.getMessages().length);
        //}
        resolve(messageContainer);
      }
    });
  }

  /**
   * @deprecated currently replaced by "prepareMessageV5"
   * prepare message with combination of present proof and offer credential + issuance with one QR-Code
   * Use-Cases: Driving License, EU-Bank-Account-issuance, Jungheinrich, Mobile-contract-issuance, Nextbike, SEPA, teilAuto, Ticketshop (VDV)
   * https://gitlab.office.kaprion.de/Origins/Kaprion/id-ideal/web-demonstrator/-/issues/92/
   * only shows qr-code once and sends two posts (cs.split)
   * @param issuer issuer-object specifying the issuer-entity
   * @param useCRE is hardware encrypted
   * @param isPaid have been paid
   * @param isFree is free of charge
   * @param ldUrl string of url, where ld-doc to fetch
   * @param manifest instance of Manifest for credential to issue
   * @param outputdescriptorFragment fragment that specifies subtype of outputdescriptor (requires leading character by which type and subtype are identifyable. For example: "." or "-")
   * @returns complete messagecontainer which to send to backend
   */
  prepareMessageLongForSICP(issuer: Issuer, useCRE: boolean, isPaid: boolean, isFree: boolean, ldUrl?: string, manifest?: CredentialManifest, outputdescriptorFragment = "") {
    return new Promise((resolve, reject) => {
      this.credentialArray = this.localDataStorageService.getData("credentialArray");
      let promiseArray: Promise<any>[] = []
      let sessionId = this.localDataStorageService.getData("sessionId");
      let taskType = this.localDataStorageService.getData("taskType");
      let counter = 0;
      if ((taskType === 'cs.icp.split.offerCredential')) {
        let oobid = crypto.randomUUID();
        this.localDataStorageService.setData("oobid", oobid);
        let messageContainer = new MessageContainer(sessionId, oobid, "de.kaprion.icp.s2p", this.credentialArray.length, "cs.icp.split.offerCredential");
        this.requestPresentations = this.localDataStorageService.getData("requestPresentation");
        this.getLdDoc().then((config: any) => {
          this.getInputDescriptors(this.requestPresentations).then(inputDescs => {
            if (this.localDataStorageService.getData("purposeObjectArray").length > 0) {
              //this.replacePurposeOnInputDescriptors(inputDescs, this.localDataStorageService.getData("purposeObjectArray"));
            }
            if (this.credentialArray.length === 0 || this.credentialArray.length === 1) {
              for (let i = 1; i <= this.credentialArray.length; i++) {
                let issuanceOfferCred: IssuanceOfferCredential;
                let messageObject = {};
                promiseArray[counter] = new Promise((resolve) => {
                  resolve(
                    this.getOutputDescriptors(i - 1, outputdescriptorFragment).then((configs: any) => {
                      new Promise((resolve) => {
                        if (!ldUrl) { ldUrl = environment['DC_URL'] + "/credentialSubject/v7"; }
                        if ((manifest === null || typeof manifest === "undefined") && outputdescriptorFragment.length === 0) {

                          if (this.requestPresentations.length > 0) {
                            if (Array.isArray(inputDescs)) {
                              manifest = new CredentialManifest("0.1.0", issuer, [configs.out], crypto.randomUUID(), inputDescs);
                            } else {
                              manifest = new CredentialManifest("0.1.0", issuer, [configs.out], crypto.randomUUID(), [])
                            }
                            issuanceOfferCred = new IssuanceOfferCredential(oobid, "de.kaprion.icp.s2p", manifest, [this.credentialArray[i - 1]], [config], [configs.schemas as any], false, true);
                            Object.defineProperty(messageObject, "issuanceOfferCredential", {
                              enumerable: true,
                              configurable: false,
                              writable: true,
                              value: issuanceOfferCred.toJSON()
                            });
                            messageContainer.setMessageObject(messageObject);
                            resolve(messageContainer)
                          };
                        };
                      });
                    }));
                });
                counter++
              }
              //intermediate bugfixing for not properly stored messages
              this.getAllPromises(promiseArray, messageContainer).then(finalCheck => {
                if (messageContainer.getMessages().length < messageContainer.getTaskAmount()) {
                  messageContainer.setTaskAmount(this.credentialArray.length);
                }
                resolve(messageContainer);
              });
            }
          });
        });
      } else if (taskType === "cs.icp.split.issueCredential") {
        let oobid = this.localDataStorageService.getData("oobid");
        let messageContainer = new MessageContainer(sessionId, oobid, "de.kaprion.icp.s2p", this.credentialArray.length, "cs.icp.split.issueCredential");
        let issuanceIssueCred = new IssuanceIssueCredential(oobid, "de.kaprion.icp.s2p", this.credentialArray, true)
        for (let i = 1; i <= this.credentialArray.length; i++) {
          let issuanceObj = {};
          Object.defineProperty(issuanceObj, "issuanceIssueCredential", { value: issuanceIssueCred.toJSON(), writable: true, enumerable: true });
          messageContainer.setMessageObject(issuanceObj);
          if (i === this.credentialArray.length) {
            //intermediate bugfixing for not properly stored messages
            //promise not needed potentially
            this.getAllPromises(promiseArray, messageContainer).then(finalCheck => {
              if (messageContainer.getMessages().length < messageContainer.getTaskAmount()) {
                messageContainer.setTaskAmount(this.credentialArray.length);
              }
            });
          }
        }
        resolve(messageContainer);
      }
    });
  }

  /**
 * shortened version generating message for all types
 * Use Cases not included: KDK, SCC-issuance
 * only shows qr-code once and sends two posts (cs.split)
 * @param issuer issuer-object specifying the issuer-entity
 * @param useCRE is hardware encrypted
 * @param isPaid have been paid
 * @param isFree is free of charge
 * @returns reduced messagecontainer which to send to backend for SICP
 */
  prepareMessageShortenedVersion(issuer: Issuer, useCRE: boolean, isPaid: boolean, isFree: boolean) {
    return new Promise((resolve, reject) => {
      this.credentialArray = this.localDataStorageService.getData("credentialArray");
      let promiseArray: Promise<any>[] = []
      let sessionId = this.localDataStorageService.getData("sessionId");
      let taskType = this.localDataStorageService.getData("taskType");
      let counter = 0;
      this.credentialArray = this.localDataStorageService.getData('credentialArray');
      //let messageContainer = new MessageContainer(sessionId, oobid, taskType, this.credentialArray.length, taskType);
      if ((taskType === 'cs.icp.split.offerCredential')) {
        let oobid = crypto.randomUUID();
        this.localDataStorageService.setData("oobid", oobid);
        let messageContainer = new MessageContainer(sessionId, oobid, "de.kaprion.icp.s2p", this.credentialArray.length, "cs.icp.split.offerCredential");
        this.requestPresentations = this.localDataStorageService.getData("requestPresentation");
        if (this.credentialArray.length === 0 || this.credentialArray.length === 1) {
          for (let i = 1; i <= this.credentialArray.length; i++) {
            let issuanceOfferCred: IssuanceOfferCredentialShort;
            //promiseArray[counter] = new Promise((resolve) => {
            if (this.requestPresentations.length > 0) {
              // let purposeObjectArray = this.localDataStorageService.getData("purposeObjectArray");
              // if (purposeObjectArray.length > 0) {
              //   this.requestPresentations = this.replacePurposeOnInputDescriptors(this.requestPresentations, purposeObjectArray);
              // }
              let request = new ValidationRequestPresentationModel(oobid, crypto.randomUUID(), this.requestPresentations);
              issuanceOfferCred = new IssuanceOfferCredentialShort(issuer, this.credentialArray, useCRE, isPaid, isFree, request);
              messageContainer.setMessageObject(issuanceOfferCred);
              resolve(messageContainer)
            };
            //});
            //counter++
          }
          //intermediate bugfixing for not properly stored messages
          // this.getAllPromises(promiseArray, messageContainer).then(finalCheck => {
          //   if (messageContainer.getMessages().length < messageContainer.getTaskAmount()) {
          //     messageContainer.setTaskAmount(this.credentialArray.length);
          //   }
          //   resolve(messageContainer);
          // });
        }
      } else if (taskType === "cs.icp.split.issueCredential") {
        let oobid = this.localDataStorageService.getData('oobid');
        let messageContainer = new MessageContainer(sessionId, oobid, "de.kaprion.icp.s2p", this.credentialArray.length, "cs.icp.split.issueCredential");
        let issuanceIssueCred = new IssuanceIssueCredentialShortened(this.credentialArray, true)
        for (let i = 1; i <= this.credentialArray.length; i++) {
          // let issuanceObj = {};
          // Object.defineProperty(issuanceObj, "issuanceIssueCredential", { value: issuanceIssueCred.toJSON(), writable: true, enumerable: true });
          messageContainer.setMessageObject(issuanceIssueCred);
          if (i === this.credentialArray.length) {
            //intermediate bugfixing for not properly stored messages
            //promise not needed potentially
            //this.getAllPromises(promiseArray, messageContainer).then(finalCheck => {
            if (messageContainer.getMessages().length < messageContainer.getTaskAmount()) {
              messageContainer.setTaskAmount(this.credentialArray.length);
            }
            //});
          }
        }
        resolve(messageContainer);
      } else if (taskType === 'de.kaprion.icp.s2p') {
        let oobid = this.localDataStorageService.getData("oobid");
        if (oobid.length === 0) {
          oobid = crypto.randomUUID();
        }
        this.localDataStorageService.setData("oobid", oobid)
        let messageContainer = new MessageContainer(sessionId, oobid, taskType, this.credentialArray.length, taskType);
        for (let i = 1; i <= this.credentialArray.length; i++) {
          let messageObject = {};

          Object.defineProperty(messageObject, "cPersonalized", {
            enumerable: true,
            configurable: false,
            writable: true,
            value: [this.credentialArray[i - 1]]
          });
          Object.defineProperty(messageObject, "issuer", {
            enumerable: true,
            configurable: false,
            writable: true,
            value: issuer !== null ? issuer : ""
          });
          Object.defineProperty(messageObject, "useCre", {
            enumerable: true,
            configurable: false,
            writable: true,
            value: true
          });
          Object.defineProperty(messageObject, "isPaid", {
            enumerable: true,
            configurable: false,
            writable: true,
            value: false
          });
          Object.defineProperty(messageObject, "isFree", {
            enumerable: true,
            configurable: false,
            writable: true,
            value: true
          });
          messageContainer.setMessageObject(messageObject);
        }
        if (messageContainer.getMessages().length < messageContainer.getTaskAmount()) {

          messageContainer.setTaskAmount(this.credentialArray.length);
        }
        delete messageContainer["cPersonalized"];
        resolve(messageContainer);
      } else if (taskType === 'de.kaprion.ppp.s2p') {
        this.requestPresentations = this.localDataStorageService.getData("requestPresentation");
        let oobid = this.localDataStorageService.getData('ticketIdArray')[0];
        let messageContainer = new MessageContainer(sessionId, oobid, taskType, this.credentialArray.length, taskType);
        let messageObject = {};
        let nonce = this.localDataStorageService.getData('ticketIdArray')[0];
        let request = new ValidationRequestPresentationModel(oobid, nonce, this.requestPresentations);
        Object.defineProperty(messageObject, 'requestPresentation', {
          enumerable: true, configurable: false, writable: true, value: request.toJSON()
        });

        messageContainer.setMessageObject(messageObject);
        messageContainer.setTaskAmount(messageContainer.getMessages().length);
        resolve(messageContainer);
      }
    });
  }


  /**
   * ensures message-containers are built completely
   * @param array array of Promises to be resolved before finally resolving
   * @param container messagecontainer to be returned finally when all Promises have been resolved
   * @returns Promise with message-container
   */
  getAllPromises(array: Promise<any>[], container: MessageContainer): Promise<any> {
    return new Promise((resolve, reject) => {
      Promise.all(array).then(finished => {
        resolve(container);
      });
    });
  }
}
