import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalDataStorageService } from 'src/app/services/local-data-storage.service';
import { environment } from 'src/environments/environment';
import { BesuchernachweisModel } from 'src/app/models/kaprion/vc/besuchernachweis.model';
import { CredentialBuilderService } from 'src/app/services/credential-builder.service';
import { CreateMessageService } from 'src/app/services/create-message.service';
import { MessageContainer } from 'src/app/models/messages/messageContainer.model';
import { DataService } from 'src/app/services/data.service';
import {Issuer} from 'src/app/models/issuer.model';
import { MessageType } from 'src/app/components/ui/dialog-handler/dialog-handler.component';

@Component({
  selector: 'app-besuchernachweis',
  templateUrl: './besuchernachweis.component.html',
  styleUrls: ['./besuchernachweis.component.scss']
})
export class DefaultCredentialComponent implements OnInit {

  sessionId: string = "";
  ticketIdArray: string[] = [];
  oobid: string = "";
  oobids: string[] = [];
  credentialArray: any[] = [];
  currentParty: string = "";
  showQRCode: boolean = false;
  appUrl: string = "";
  messageText: string = "";
  statusOk: boolean = false;
  messageArray: string[] = [];
  alertMessage: string = "";
  hintMessage: string = "";
  messageType: MessageType | undefined = undefined;

  blnShowDialogHandler:boolean = false;

  constructor(private activatedRoute: ActivatedRoute,
    private localDataStorageService: LocalDataStorageService,
    private csb: CredentialBuilderService,
    private createMessageService: CreateMessageService,
    private dataService: DataService,
    private router: Router) {
    this.activatedRoute.params.subscribe(params => params)
  }

  placeholderString: string = "";

  credSubject: BesuchernachweisModel = {
    id: "iss:REPLACE_ME_HOLDER",
    standName: "KAPRION Technologies GmbH",
    beschreibung: "IDeal WALLET: Wir entwickeln die Zukunft unserer digitalen Identität | Absolut fälschungs- und kopiersicher durch Verwendung von Crypto-Chips | Ausgabe des digitalen Zwillings über Behörden | Digital mit unserer Wallet überall authentifizieren können | Interesse geweckt? Sprechen Sie uns an!"
  };

  htw_credSubject: BesuchernachweisModel = {
    id: "iss:REPLACE_ME_HOLDER",
    standName: "Trustnet Café",
    beschreibung: "Ziel der TrustNet-Initiative ist die internationale Etablierung eines Trust Frameworks als Werkzeug zur Realisierung des TrustNets. Die Nutzung und Verbreitung des Trust Frameworks soll zum Entstehen eines Ökosystems von ID-Inhabern, Herausgebern und Akzeptanzstellen führen."
  };

  hmw_credSubject: BesuchernachweisModel = {
    id: "iss:REPLACE_ME_HOLDER",
    standName: "Hochschule Mittweida",
    beschreibung: "Blockchain Competence Center Mittweida demonstriert mit ihrer Hidy Wallet verschiedene Interoperabilitätsszenarios."
  };

  lhd_credSubject: BesuchernachweisModel = {
    id: "iss:REPLACE_ME_HOLDER",
    standName: "Landeshauptstadt Dresden",
    beschreibung: "Landeshauptstadt Dresden präsentiert: Innovative digitale Lösungen für den Dresden Pass, Bürgerbegehren und kDK. Effizienz und Transparenz für die Bürgerverwaltung - Zukunft gestalten!"
  };

  sl_credSubject: BesuchernachweisModel = {
    id: "iss:REPLACE_ME_HOLDER",
    standName: "Stadt Leipzig",
    beschreibung: "Der Demonstrator der Stadt Leipzig zeigt die digitale Kursbuchung bei der Volkshochschule Leipzig mithilfe einer Wallet und SSI-Crendentials. Einmal in der Wallet abgelegt, kann der digitale Nachweis genutzt werden, um ihn bei Kursantritt vorzuzeigen."
  };

  eecc_credSubject: BesuchernachweisModel = {
    id: "iss:REPLACE_ME_HOLDER",
    standName: "EECC European EPC Competence Center",
    beschreibung: "Das Handels- und Logistikszenario wird vom EECC unter Triebkraft der GS1 Germany pilotiert, die sektorübergreifende Nutzung von ID-Daten auf der Basis sicherer, authentischer und interoperabler Nutzer-, Organisations- und Produktidentitäten schafft dafür die Grundlage und ist die Initialzündung für eine sichere und vor allem, aus Nutzersicht gerechte, selbst-souveräne Datenverwertung entlang der Supply Chain."
  };

  fit_credSubject: BesuchernachweisModel = {
    id: "iss:REPLACE_ME_HOLDER",
    standName: "Fraunhofer FIT",
    beschreibung: "Am Stand des Fraunhofer FIT wird der Use Case Energie vorgestellt. Dieser Use Case entwickelt auf Basis des Self-Sovereign Identity Paradigmas und digitalen Maschinenidentitäten eine Architektur, mit der Treibhausgasemissionen feingranular über die gesamte Stromlieferkette hinweg ausgewiesen und verifizierbar nachverfolgt werden können. Auf Basis des von uns entwickelten Lösungsansatzes wird es Endkund*innen sowie Industrieunternehmen möglich sein, feingranulare Echtzeit-Informationen zur Treibhausgasintensität ihrer Stromnachfrage abzurufen."
  };

  outputdescriptorFragment: string = "";


  ngOnInit(): void {
    this.sessionId = crypto.randomUUID();
    this.localDataStorageService.setData("sessionId", this.sessionId)
    this.currentParty = this.localDataStorageService.getData("currentParty");
    this.placeholderString = this.getPlaceholderStringByParty();

  }

  getPlaceholderStringByParty() {
    let placeholder = "";
    let party = this.localDataStorageService.getData("currentParty");
    this.outputdescriptorFragment = "";
    switch (party) {
      case "KAPRION Technologies GmbH":
        placeholder = this.credSubject.beschreibung + this.credSubject.standName;
        this.outputdescriptorFragment = ".kaprion";
        break;
      case "Trustnet Café":
        placeholder = this.htw_credSubject.beschreibung + this.htw_credSubject.standName;
        this.outputdescriptorFragment = ".htwd";
        this.credSubject = this.htw_credSubject;
        break;
      case "Hochschule Mittweida":
        placeholder = this.hmw_credSubject.beschreibung + this.hmw_credSubject.standName;
        this.outputdescriptorFragment = ".hmw";
        this.credSubject = this.hmw_credSubject;
        break;
      case "Landeshauptstadt Dresden":
        placeholder = this.lhd_credSubject.beschreibung + this.lhd_credSubject.standName;
        this.outputdescriptorFragment = ".dresden";
        this.credSubject = this.lhd_credSubject;
        break;
      case "Stadt Leipzig":
        placeholder = this.sl_credSubject.beschreibung + this.sl_credSubject.standName;
        this.outputdescriptorFragment = ".leipzig";
        this.credSubject = this.sl_credSubject;
        break;
      case "EECC European EPC Competence Center":
        placeholder = this.eecc_credSubject.beschreibung + this.eecc_credSubject.standName;
        this.outputdescriptorFragment = ".eecc";
        this.credSubject = this.eecc_credSubject;
        break;
      case "Fraunhofer FIT":
        placeholder = this.fit_credSubject.beschreibung + this.fit_credSubject.standName;
        this.outputdescriptorFragment = ".fit";
        this.credSubject = this.fit_credSubject;
        break;
    }
    return placeholder;
  }

  /**
   * Returns an ISO 8601 date-time string
   * @param deltaDay
   */
  private getDateTime(deltaDay: number = 2): string {
    let date = new Date();
    if (deltaDay > 0) {
      date.setHours(0, 0, 0, 0); // reset hours, minutes, seconds and milliseconds
      date.setDate(date.getDate() + deltaDay)
    }
    return date.toISOString();
  }

  /**
   * Resets the credential to default values
   */
  resetCredential() {
    this.credSubject = {
      standName: "KAPRION Technologies GmbH",
      beschreibung: "Der Eigentümer dieses Nachweises hat den Stand von "
    };
  }

  /**
   * Builds a BesuchernachweisModel Credential
   * TODO("add ngForms")
   */
  // demo-fill form
fillCredential() {
    this.oobid = crypto.randomUUID();
    this.oobids.push(this.oobid);

    let credentialContext = environment['DC_URL'] + "/credentialSubject/v7";
    let id = environment['HOME_URL'] + "/samples/KAPRION/Besuchernachweis/" + this.oobid;

    let credential = this.csb
      .id(id)
      .addContext(credentialContext)
      .addType("Besuchernachweis")
      .issuanceDate()
      .expirationDate(this.getDateTime())
      .credentialSubject(this.credSubject)
      .build();

    this.ticketIdArray.push(id);
    this.credentialArray.push(credential);
  }

  /*
   * This function (or parts) shall be moved into a server controller class
   */
  onFinalSubmit() {
    this.fillCredential()
    this.localDataStorageService.setData("credentialArray", this.credentialArray);
    this.localDataStorageService.setData("ticketIdArray", this.ticketIdArray);
    this.localDataStorageService.setData("taskType", "de.kaprion.icp.s2p");
    let message = this.createMessageService.prepareMessageLongWithConfigs(Issuer.getIDIdealIssuer(), true, true, false, undefined, undefined, this.outputdescriptorFragment).then((msg:any) => {
      if (msg instanceof MessageContainer) {
        if (msg.getMessages().length < msg.getTaskAmount()) {
          msg.setTaskAmount(msg.getMessages().length);
        }

        msg = JSON.stringify(msg, (key, value) => {
          if (value === undefined || value === null) {
            return undefined;
          }
          // Filtering null and undefined values from arrays
          if (Array.isArray(value)) {
            return value.filter((item) => item !== null && item !== undefined);
          }
          return value;
        });

        this.dataService.send(msg, environment['DC_URL'] + '/initSession').then((response:any) => {
          if(response['success']) {
            this.statusOk = true;
            this.messageText = "Ihr SCC-Nachweis wurde erfolgreich ausgestellt";
            this.messageArray.push(this.messageText);
          } else {
            this.messageText = "Nachweise konnten nicht verarbeitet werden: Status " + response['details']
            this.messageArray.push(this.messageText);
            this.statusOk = false;
          }
          setTimeout(() => {
            this.messageText = "";
          }, 5000)
          this.ticketIdArray = [];
          this.credentialArray = [];
          this.oobids = [];
          this.resetCredential();
        }).catch((e: Error) => {

          console.warn("Rejected: " + e);
        });
      }
      // this.router.navigate(['/qrcode/' + this.sessionId]);
      //this.localDataStorageService.setData("oobid", this.oobid);
      this.showQRCode = true;
    });
  }

  //abort-functionality | back-button
  onClickBack() {
    this.messageType = MessageType.OkCancelDialog;
    this.alertMessage = "Möchten Sie zur Credentialauswahl zurückkehren?";
    this.hintMessage = "";
    this.blnShowDialogHandler = true;
  }
    /**
   * catches emitter from dialog-handler
   */
  cancelAction() {
    this.blnShowDialogHandler = false;
  }

  /**
 * catches emitter from dialog-handler
 */
  okAction() {
    this.blnShowDialogHandler = false;
  }
}
