<header>openID4VP-Verifizierung</header>

<div class="main-wrapper container-fluid">

  <strong class="loader-message" *ngIf="showLoader">Bitte warten, während wir den QR-Code vorbereiten</strong>
  <app-waiter *ngIf="showLoader"></app-waiter>

  <div style="display: block;" class="vh-73">
    <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3" *ngIf="showQrCode">
      <div class="card border-0" style="background-color: #00000000;">
        <app-showqrcode class="qr-code" [verificationUrl]="appUrl"></app-showqrcode>
      </div>
    </div>
  </div>

</div>

<dialog id="dialog-box-alert">
  <h3>Es ist ein Fehler aufgetreten</h3>
  <p>{{alertMessage}}</p>
  <p *ngIf="hintMessage.length > 0">{{hintMessage}}</p>
  <div class="inline">
    <button (click)="closeMessage()" class="link-box-white">Ok</button>
  </div>
</dialog>