export class Inputdescriptor {

  type: string = "";
  subtype: string | undefined = undefined

  constructor(type: string, subtype?: string) {
    this.type = type;
    this.subtype = subtype;
  }

  toJSON() {
    let obj = {};
    if (typeof this.type === 'string') {
      Object.defineProperty(obj,'type', {value: this.type, enumerable: true})
    }
    if (typeof this.subtype === 'string') {
      Object.defineProperty(obj,'subtype', {value: this.subtype, enumerable: true})
    }
    return obj;
  }
}