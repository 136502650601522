import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, map, takeWhile, timer } from 'rxjs';
import { Issuer } from 'src/app/models/issuer.model';
import { BankAccountDisposalAuthorization } from 'src/app/models/kaprion/vc/bank-account.model';
import { MobileContractCredenial } from 'src/app/models/kaprion/vc/mobile-contract.model';
import { MessageContainer } from 'src/app/models/messages/messageContainer.model';
import { EvidenceModel } from 'src/app/models/w3c/vcdm/evidence.model';
import { VerifiableCredential1xModel } from 'src/app/models/w3c/vcdm/verifiable-credential-1x.model';
import { CreateMessageService } from 'src/app/services/create-message.service';
import { DataService } from 'src/app/services/data.service';
import { LocalDataStorageService } from 'src/app/services/local-data-storage.service';
import { EnvService } from 'src/environments/environment';

@Component({
  selector: 'app-mobile-contract-login',
  templateUrl: './mobile-contract-login.component.html',
  styleUrls: ['./mobile-contract-login.component.scss']
})
export class MobileContractLoginComponent implements OnInit {

  @Input() requestedCredential: BankAccountDisposalAuthorization | null = null;


  loginInformation: any;
  canLogin = false;
  isLoggedIn: boolean = true;
  messageText: string = "";
  proofArray: string[] = [];
  sessionId: string = "";
  ticketIdArray: string[] = [];
  oobid: string = "";
  credentialArray: any[] = [];
  evidence: EvidenceModel[] = [];
  showQrCode = false;
  buttonText: string = "Anmelden";
  credentialSubjects: { [key: string]: any }[] = [];
  appUrl: string = ""
  isRequestPresentation: boolean = true;
  credSubject: MobileContractCredenial;
  requestPresentation: string[] = ["MobilePhoneContract"];
  statusOk: boolean = false;
  alertMessage: string = "";
  hintMessage: string = "";
  messageArray: string[] = [];
  presentationID: string = ""

  balance: number = 0.00;
  owner: string = "";
  iban: string = "";

  loginTime: number;
  remainingTime = timer(0, 1000).pipe(
    map(n => (5 * 60 - n) * 1000),
    takeWhile(n => n >= 0),
    finalize(() => {
      this.messageText = "Sie werden nun ausgeloggt";
      this.statusOk = true;
      this.messageArray.push(this.messageText);
      setTimeout(() => {
        this.router.navigate(["/home"]);
      }, 5000)
    })
  );
  isLogoutWarning = false;
  logoutWarningTime: any;
  fragments: string[] | null = [];

  constructor(
    private localDataStorageService: LocalDataStorageService,
    private router: Router,
    private createMessageService: CreateMessageService,
    private dataService: DataService,
    private envService: EnvService) {
    this.credSubject = {
      id: "iss:REPLACE_ME_HOLDER",
      customerId: "",
      brand: "Mini",
      price: 9.99,
      phoneNumber: '0177/12345678', //valid Iban
      isProtectedByCRE: true,
    };
    this.loginInformation = this.localDataStorageService.getData("loginInformation");
    this.owner = "Erika Mustermann";
    this.loginTime = Date.now();
  }

  ngOnInit(): void {
    this.localDataStorageService.setData("navigationalFragment", "/mobile-phone-login");
    this.isLoggedIn = false;
    this.showQrCode = true;
    this.credentialArray = ['MobilePhoneContract']
    this.localDataStorageService.setData("credentialArray", this.credentialArray)
    this.genPPPMessage().then(result => {
      this.showQrCode = false;
      this.isLoggedIn = true;
    });
  }

  private genPPPMessage() {
    return new Promise((resolve, reject) => {
      this.presentationID = crypto.randomUUID();
      this.localDataStorageService.setData("ticketIdArray", [this.presentationID]); // presentation_definition.id
      this.localDataStorageService.setData("requestPresentation", this.requestPresentation);
      this.localDataStorageService.setData("taskType", "de.kaprion.ppp.s2p");
      this.createMessageService.prepareMessageV2(Issuer.getMobileIssuer(), this.presentationID).then((msg: any) => {
        if (msg instanceof MessageContainer) {
          if (msg.getMessages().length < msg.getTaskAmount()) {
            msg.setTaskAmount(msg.getMessages().length);
          }

          msg = JSON.stringify(msg, (key, value) => {
            if (value === undefined || value === null) {
              return undefined;
            }
            // Filtering null and undefined values from arrays
            if (Array.isArray(value)) {
              return value.filter((item) => item !== null && item !== undefined);
            }
            return value;
          });
          this.appUrl = this.envService.env['APP_URL'] + "/verify" +
            "?_oobid=" + this.presentationID +
            "&credentialProviderLink=" + this.envService.env['DC_URL'] + "/validation";

          this.dataService.sendExpectJSON(msg, this.envService.env['DC_URL'] + '/initSessionValidation').then((response: any) => {
            let attach = response['attachments'][0]['data']['json']['verifiableCredential'];
            if (Array.isArray(attach)) {
              this.credentialArray = attach;
            }
            else {
              this.credentialArray.push(attach);
            }
            this.credentialArray.forEach(credential => {
              if ((<VerifiableCredential1xModel>credential).type.includes(this.requestPresentation[0])) {
                this.processReceivedVC(<VerifiableCredential1xModel>credential);
              }
            });
            resolve(this.credentialArray);
          }).catch((e: any) => {
            console.warn("Rejected: " + e);
            reject("Something went wrong: " + e)
          });
        }
      });
      this.appUrl = this.envService.env['APP_URL'] + "/verify" +
        "?_oobid=" + this.presentationID +
        "&credentialProviderLink=" + this.envService.env['DC_URL'] + "/validation";
      this.showQrCode = true;
      this.isRequestPresentation = true;

    })
  }

  checkRequestedItem(requestedItem: string) {
    return this.requestPresentation.includes(requestedItem);
  }

  checkEntry() {
    return this.requestPresentation.filter(entry => !this.proofArray.includes(entry));
  }

  processReceivedVC(credential: VerifiableCredential1xModel) {
    let vcType = this.evaluateForm(credential)
    if (vcType.length > 0) {
      let evidence: EvidenceModel = {
        docId: (' ' + credential.id).slice(1),
        documentPresence: 'Digital',
        evidenceDocument: vcType,
        id: '__HOME_URL__/Verification/' + crypto.randomUUID(),
        subjectPresence: 'Physical',
        type: ['DocumentVerification'],
        verifier: this.envService.env['HOME_URL'] + '/samples/KAPRION/SysActor/8506cbb4-c8d0-4b34-8f93-c5638893f0e2'
      };
      this.proofArray.push(vcType);
      this.evidence.push(evidence);
      this.canLogin = true;
    }
  }

  evaluateForm(cred: VerifiableCredential1xModel): string {
    let foundType: string = "";
    this.requestPresentation.forEach(type => {
      if (cred.type.includes(type)) {
        foundType = type;
      }
    })
    return foundType
  }

  //abort-functionality | back-button
  onClickBack() {
    this.buttonText = "Anmelden";
  }

  onClickCancel() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[0] as any).showModal();
  }

  // request confirmation
  onDialogCancel() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[0] as any).close();
  }

  onDialogOk() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[0] as any).close();
    this.localDataStorageService.setData("navigationalFragment", "");
    this.router.navigate(["home"]);
  }

  onDialogBack() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[1] as any).close();
    this.sessionId = this.localDataStorageService.getData("sessionId");
    this.buttonText = "Eingaben prüfen";
    this.credentialSubjects = [];
    this.credentialArray = [];
    this.appUrl = "";
    this.showQrCode = false;
  }

  onDialogPresentationInvalid() {
    let dialogList = document.getElementById("dialog-box-presentation-invalid");
    (dialogList as any).close();
    this.router.navigate(['home'])
  }

}

