import { Injectable } from '@angular/core';
import { VerifiableCredential1xModel } from '../models/w3c/vcdm/verifiable-credential-1x.model';
import { Inputdescriptor } from '../models/inputdescriptor.model';

@Injectable({
  providedIn: 'root'
})
export class CredentialSanityCheckService {

  constructor() { }
/**
 * check if credential is from certain type
 * @param cred credential to check
 * @param requestedCredentialTypes array of credentialTypes to check against 
 * @returns the found type of input credential
 */
    evaluateForm(cred: VerifiableCredential1xModel, requestedCredentialTypes: Inputdescriptor[]): string {
      let foundType: string = "";
      requestedCredentialTypes.forEach((type: Inputdescriptor) => {
        if (cred.type.includes(type.type)) {
          foundType = type.type;
        }
      })
      return foundType
    }
}
