// service to handale request to server side

import { HttpErrorResponse } from '@angular/common/http';
//import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocalDataStorageService } from './local-data-storage.service';
import { environment } from 'src/environments/environment';
import { ErrorHandlingService } from './error-handling.service';

@Injectable()
export class DataService {

  pthid: string = '';
  senderDid: string = '';
  media_type: string = 'application/json';

  promiseArray: Promise<any>[] = [];
  hasReachedTimeoutForResponse = false;
  private _blnStatusSuccess: boolean[] = [];
  private _statusMessageArray: Object[] = [];

  constructor(private errorHandlingService: ErrorHandlingService) {
  }

  /**
   * to send request to server side
   * @param data
   * @returns status
   */

  get blnStatusSuccess() {
    return this._blnStatusSuccess;
  }
  /**
   * get a certain ressource using the fetch-api
   * @param url URL to GET
   * @returns ressource-object if available
   */
  get(url: string) {
    return new Promise<any>((resolve, reject) => {

      const options = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'access-control-allow-origin': '*'
        },
        method: 'GET',
      };
      fetch(url, options).then(response => {
        if (response.ok) {
          if (response.body !== undefined && response.body instanceof ReadableStream) {
            return response.json()
          } else {
            return response;
          }
        } else if (response.status === 401 || response.status === 404) {
          return response.status;
        } else {
          return response;
        }
      }).then(resp => {
        resolve(resp);
      }).catch((e: HttpErrorResponse) => {
        reject(e)
      })
    })
  }

  // TODO modify function that both situations can be handled
  /**
   * 
   * @param data data for body of POST
   * @param urlString URL to send POST to
   * @param isResponseProcessed boolean to indicate a previous POST has been processed (tied to SICP-Method)
   * @returns response or http-error-code
   */
  send(data: any, urlString: string, isResponseProcessed = false): Promise<any> {
    return new Promise((resolve, reject) => {

      if (data !== undefined) {
        data = this.replacePlaceHolder(data);

        const messagePromise = new Promise((resolve, reject) => {
          const options = {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'access-control-allow-origin': '*'
            },
            method: 'POST',
            body: data
          };
          fetch(urlString, options).then(response => {
            if (response.ok) {
              response.json().then(jsonMessage => {
                if (jsonMessage['success'] !== undefined && jsonMessage['success'] === false) {
                  let message = "Details: " + jsonMessage['details']
                  this.errorHandlingService.handleMessage({ show: true, messageText: message, status: jsonMessage['success'] })
                  reject(jsonMessage['details']);
                } else {
                  resolve(jsonMessage)
                }
              }).catch((e: any) => {
                reject("Could not resolve response to json-format")
              });
            }
          }).catch((e: HttpErrorResponse) => {
            if (e === undefined) {
              alert("No valid response provided. Please report that incident.")
              reject(e);
              return true;
            } else if (e.error === undefined || e.status === undefined) {
              alert('Netzwerkfehler: Zieladresse nicht erreichbar, Ressourcen nicht abrufbar: Bitte überprüfen Sie ihre Netzwerkverbindung.');
              reject(e);
              return (e);
            } else {
              alert('Etwas hat nicht funktioniert: ' + e.error);
              reject(e.status);
              return e.status;
            }

          });
        });
        resolve(messagePromise);
      } else {
        this.errorHandlingService.handleMessage({show: true, messageText: "An unexpected error occured. Please report that incident.", status: false})
        reject('Unexpected error.');
        //     }
        // });
      }
    });
  }
  /**
   * 
   * @param data data of body of POST
   * @param urlString URL to send POST to
   * @returns response or http-error-code
   */
  sendExpectJSON(data: any, urlString: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (data !== undefined) {
        data = this.replacePlaceHolder(data);

        const messagePromise = new Promise((resolve, reject) => {
          const options = {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'access-control-allow-origin': '*'
            },
            method: 'POST',
            body: data
          };
          fetch(urlString, options).then(response => {
            if (response.ok) {
              response.json().then(jsonMessage => {
                if (jsonMessage['success'] !== undefined && jsonMessage['success'] === false) {
                  let message = "Details: " + jsonMessage['details']
                  this.errorHandlingService.handleMessage({ show: true, messageText: message, status: jsonMessage['success'] })
                  reject(jsonMessage['details']);
                } else {
                  resolve(jsonMessage)
                }
              }).catch((e: any) => {
                this.errorHandlingService.handleMessage({show: true, messageText: "Could not resolve response to json-format", status: false})
                reject("Could not resolve response to json-format")
              });
            }
          }).catch((e: HttpErrorResponse) => {
              //console.log(e);
              // resolving e === undefined for tests
              if (e === undefined) {
                alert("No valid response provided. Please report that incident.")
                reject(e);
                return true;
              } else if (e.error === undefined || e.status === undefined) {
                alert('Netzwerkfehler: Zieladresse nicht erreichbar, Ressourcen nicht abrufbar: ' + e);
                reject(e);
                return (e);
              } else {
                alert('Etwas hat nicht funktioniert: ' + e.error);
                reject(e.status);
                return e.status;
              }
            });
        });
        resolve(messagePromise);
      } else {
        this.errorHandlingService.handleMessage({show: true, messageText: "An unexpected error occured. Please report that incident.", status: false})
        reject('Unexpected error.');
      }
    });
  }
  /**
   * 
   * @param data data to search for string
   * @returns data with replaced string
   */
  private replacePlaceHolder(data: any): any {
    let homeURL = environment['HOME_URL'];
    let tmp = data.replaceAll('__HOME_URL__', homeURL); // DEBUG procedure, reduce these lines in production to a single line
    //console.log('Data getting send: ' + tmp);           // x
    return tmp;                                         // x
  }

  /**
   * 
   * @param method HTTP-Method
   * @param url url to use method on
   * @returns statusText or response
   */
  getRequestMethod(method: string, url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let xmlHttpReq = new XMLHttpRequest();
      xmlHttpReq.open(method, url);
      xmlHttpReq.onload = function () {
        if (xmlHttpReq.status >= 200 && xmlHttpReq.status < 300) {
          resolve(xmlHttpReq.response);
        } else {
          reject({
            'status': xmlHttpReq.status,
            'statusText': xmlHttpReq.statusText
          });
        }

        xmlHttpReq.onerror = () => {
          reject({
            'status': xmlHttpReq.status,
            'statusText': xmlHttpReq.statusText
          });
        };
      };
    });
  }

  /**
   * 
   * @param urlString url to GET ressource from
   * @returns json-object that has been retrieved
   */
  retrieveFile(urlString: string) {
    return new Promise((resolve, reject) => {
      fetch(urlString).then(result => {
        resolve(result.json());
        //return result
      }).catch(err => {
        //console.log(err);  
      });
    });
  }
}
