import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { EnvService } from 'src/environments/environment';

@Component({
  selector: 'app-openid4vp-login',
  templateUrl: './openid4vp-login.component.html',
  styleUrls: ['./openid4vp-login.component.scss']
})
export class Openid4vpLoginComponent implements OnInit {

  showLoader: boolean = false;
  appUrl: string = "";
  showQrCode = false;
  alertMessage: string = "";
  hintMessage: string = "";
  sessionId: string = "";
  oobid: string = "";

  constructor(private dataService: DataService,
    private router: Router,
    private envService: EnvService) {
  }

  ngOnInit() {
    this.showLoader = true;
    this.getQRCodeData();
  }

  /**
   * to get the data for qr-code
   */
  getQRCodeData() {
    this.dataService.get(this.envService.env['DC_URL'] + '/login_vp').then(response => {
      this.showLoader = false;
      if (response !== null
        && response.url !== undefined && response.url !== ""
        && response.sid !== undefined && response.sid !== ""
      ) {
        this.appUrl = response.url;
        this.sessionId = response.sid;
        this.showQrCode = true;
        // var handle = setInterval(() => {
        //   this.getResultData().then((result: any) => {
        //     if(result !== null) {
        //       clearInterval(handle);
        //     }
        //     console.log(result);
        //   });
        // }, 5000);
      } else if ((response === null || response === "") || (response.status !== undefined && response.status !== 200)) {
        this.alertMessage = response.statusText;
      }
    });
  }

  /**
   * to close the message
   */
  closeMessage() {
    this.alertMessage = "";
    this.hintMessage = "";
  }

  /**
   * to get results
   * @returns list of 
   */
  getResultData(): Promise<any> {
    return new Promise<Object>((resolve, reject) => {
      this.dataService.get(this.envService.env['DC_URL'] + '/api/result').then(response => {
        resolve(response);
        return response;
      });
    });
  }
}