import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Issuer } from 'src/app/models/issuer.model';
import { BankAccountModel } from 'src/app/models/kaprion/vc/bada.model';
import { MessageContainer } from 'src/app/models/messages/messageContainer.model';
import { EvidenceModel } from 'src/app/models/w3c/vcdm/evidence.model';
import { VerifiableCredential1xModel } from 'src/app/models/w3c/vcdm/verifiable-credential-1x.model';
import { CreateMessageService } from 'src/app/services/create-message.service';
import { CredentialBuilderService } from 'src/app/services/credential-builder.service';
import { DataService } from 'src/app/services/data.service';
import { LocalDataStorageService } from 'src/app/services/local-data-storage.service';
import { environment } from 'src/environments/environment';
import { IbanGeneratorService } from 'src/app/services/iban-generator.service';
import { GetLangPipe } from 'src/app/pipe/get-lang.pipe';
import { CredentialSanityCheckService } from 'src/app/services/credential-sanity-check.service';
import { MessageType } from 'src/app/components/ui/dialog-handler/dialog-handler.component';

@Component({
  selector: 'app-sepa-direct-debit-mandate',
  templateUrl: './sepa-direct-debit-mandate.component.html',
  styleUrls: ['./sepa-direct-debit-mandate.component.scss']
})
export class SEPADirectDebitMandateComponent implements OnInit {

  blnShowDialogHandler:boolean = false;

  credentialSubjects: { [key: string]: any }[] = [];
  messageText: string = "";
  proofArray: string[] = [];
  sessionId: string = "";
  ticketIdArray: string[] = [];
  oobid: string = "";
  credentialArray: any[] = [];
  SEPA = {
    issued: "",
    expires: "",
    id: "",
    creditInstitution: "",
    iban: "",
    bic: "",
    repeatablePayment: false,
    intendedUse: ""
  }

  evidence: EvidenceModel[] = [];

  requestPresentation: string[] = ['KommPassPerson', 'KommPassAltersnachweis', 'KommPassAdresse'];
  isIssuance = false;
  /**
   * Credential subject variable with default values
   */
  credSubject: BankAccountModel = {
    id: "iss:REPLACE_ME_HOLDER",
    creditInstitution: "",
    iban: "", //valid Iban
    bic: "",
    isProtectedByCRE: true,
    repeatablePayment: false,
    intendedUse: ""
  };

  sepaForm: FormGroup = new FormGroup({
    creditInstitution: new FormControl('', [Validators.required]),
    iban: new FormControl('', [Validators.required]),
    bic: new FormControl('', [Validators.required]),
    repeatablePayment: new FormControl('', [
      Validators.required
    ]),
    intendedUse: new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(2)
    ])),
  });

  statusOk: boolean = false;
  presentationID: string = "";
  state: StateMachine = StateMachine.REQ_PRESENTATION;
  isSEPA: boolean = false;
  isRequestPresentation: boolean = false;
  showQrCode: boolean = false;
  appUrl: string = "";
  isInput: boolean = false;
  buttonText: string = "Eingaben prüfen";
  buttonTextNew: string = "SEPA-Lastschriftmandat ausstellen";

  isDisplaySepa = false;
  isDisplayPerson = false;
  isDisplayAddress = false;
  isDisplayAgeProof = false;

  alertMessage: string = "";
  hintMessage: string = "";
  messageType: MessageType | undefined = undefined;

  messageArray: string[] = [];

  purposeObjectArray: { [key: string]: any }[] = [];
  readonly credentialType = "SEPADirectDebitMandate";

  constructor(
    private dataService: DataService,
    private createMessageService: CreateMessageService,
    private localDataStorageService: LocalDataStorageService,
    private router: Router,
    private csb: CredentialBuilderService,
    private ibanGenService: IbanGeneratorService,
    private cscService: CredentialSanityCheckService) {
  }

  ngOnInit(): void {
    this.sessionId = crypto.randomUUID();
    this.localDataStorageService.setData("sessionId", this.sessionId);
    this.isInput = true;
    this.state = StateMachine.INPUT_SEPA;
  }

  /**
   * Returns an ISO 8601 date-time string
   * @param deltaYear
   */
  getDateTime(deltaYear: number = 0) {
    let date = new Date();
    if (deltaYear > 0) {
      date.setHours(0, 0, 0, 0); // reset hours, minutes, seconds and milliseconds
      date.setFullYear(date.getFullYear() + deltaYear);
    }
    return date.toISOString();
  }

  /**
   * Resets the credential to default values
   */
  resetCredential() {
    this.credSubject = {
      id: "iss:REPLACE_ME_HOLDER",
      creditInstitution: "IDIdeal Bank",
      iban: "DE88100900001234567892",
      bic: "IDI18500111",
      repeatablePayment: false,
      intendedUse: ""
      // isProtectedByCRE: true
    };
  }

  fillCredential() {
    this.oobid = crypto.randomUUID();

    this.credSubject.creditInstitution = this.sepaForm.controls['creditInstitution'].value;
    this.credSubject.iban = this.sepaForm.controls['iban'].value;
    this.credSubject.bic = this.sepaForm.controls['bic'].value;
    this.credSubject.repeatablePayment = this.sepaForm.controls['repeatablePayment'].value;
    this.credSubject.intendedUse = this.sepaForm.controls['intendedUse'].value;


    let credentialContext = environment['DC_URL'] + "/credentialSubject/v7";
    let id = environment['HOME_URL'] + "/samples/KAPRION/" + this.credentialType + "/" + this.oobid;

    let credential = this.csb
      .id(id)
      .addContext(credentialContext)
      .addType(this.credentialType)
      .issuanceDate()
      .expirationDate()
      .evidence(this.evidence)
      .credentialSubject(this.credSubject)
      .build();

    this.ticketIdArray.push(id);
    this.credentialArray.push(credential);
  }

  processReceivedVC(credential: VerifiableCredential1xModel) {
    let vcType = this.cscService.evaluateForm(credential, this.requestPresentation);
    this.credSubject.creditInstitution = this.sepaForm.controls['creditInstitution'].value;
    this.credSubject.iban = this.sepaForm.controls['iban'].value;
    this.credSubject.bic = this.sepaForm.controls['bic'].value;
    if (vcType.length > 0) {
      let evidence: EvidenceModel = {
        docId: (' ' + credential.id).slice(1),
        documentPresence: 'Digital',
        evidenceDocument: vcType,
        id: '__HOME_URL__/Verification/' + crypto.randomUUID(),
        subjectPresence: 'Physical',
        type: ['DocumentVerification'],
        verifier: environment['HOME_URL'] + '/samples/KAPRION/SysActor/8506cbb4-c8d0-4b34-8f93-c5638893f0e2'
      };
      this.proofArray.push(vcType);
      this.evidence.push(evidence);
    }
  }

  displaySEPA() {
    this.isInput = false;
    this.isDisplaySepa = true;
    this.showQrCode = false;
    this.state = StateMachine.REQ_PRESENTATION;
    this.isRequestPresentation = true;
    // this.sepaForm.controls['creditInstitution'].disable()
    // this.sepaForm.controls['iban'].disable()
    // this.sepaForm.controls['bic'].disable()
    // this.sepaForm.controls['repeatablePayment'].disable()
    // this.sepaForm.controls['intendedUse'].disable()
    this.sepaForm.disable()
  }

  onFinalSubmit() {
    if (this.state === StateMachine.INPUT_SEPA) {
      this.displaySEPA()
    } else {
      this.isDisplaySepa = false;
      this.isRequestPresentation = false;
      this.issueSepa()
    }
  }

  expandRequestPresentationByPurpose() {
    this.requestPresentation.forEach((cType: string, index: number) => {
      let purposeString: string = '';
      if (cType.includes('KommPass')) {
        purposeString = 'Wir benötigen Ihre ' + cType.split('KommPass')[1] + '-Informationen, um Ihr ' + new GetLangPipe().transform(this.credentialType) + ' ausstellen zu können.'
      } else if (cType.includes('KDK')) {
        purposeString = 'Wir benötigen Ihre ' + new GetLangPipe().transform((cType.split('KDK')[1].split('K')[0])) + '-Informationen, um Ihr ' + new GetLangPipe().transform(this.credentialType) + ' ausstellen zu können.'
      } else {
        purposeString = 'Ihre Bankverbindung wird benötigt, um Ihr ' + new GetLangPipe().transform(this.credentialType) + ' ausstellen zu können.';
      }
      let obj = {
        [cType]: {
          'purpose': purposeString
        }
      }
      this.purposeObjectArray.push(obj);
      this.localDataStorageService.setData("purposeObjectArray", this.purposeObjectArray)
    })
  }

  private issueSepa() {

    this.credSubject.creditInstitution = this.sepaForm.controls['creditInstitution'].value;
    this.credSubject.iban = this.sepaForm.controls['iban'].value;
    this.credSubject.bic = this.sepaForm.controls['bic'].value;
    this.credSubject.repeatablePayment = this.sepaForm.controls['repeatablePayment'].value;
    this.credSubject.intendedUse = this.sepaForm.controls['intendedUse'].value;

    let credentialContext = environment['DC_URL'] + "/credentialSubject/v7";
    let id = environment['HOME_URL'] + "/samples/KAPRION/" + this.credentialType+ "/" + crypto.randomUUID();

    let credential = this.csb
      .id(id)
      .addContext(credentialContext)
      .addType(this.credentialType)
      .issuanceDate()
      .evidence(this.evidence)
      .expirationDate(this.csb.getDateTime(true, 1))
      .credentialSubject(this.credSubject)
      .build()

    // send VC

    this.localDataStorageService.setData("credentialArray", [credential]);
    this.localDataStorageService.setData("ticketIdArray", [id]);
    this.localDataStorageService.setData("taskType", "cs.icp.split.offerCredential");
    this.localDataStorageService.setData("requestPresentation", this.requestPresentation);
    this.expandRequestPresentationByPurpose();

    let message = this.createMessageService.prepareMessageShortenedVersion(Issuer.getIDIBankIssuer(), true, true, false).then((msg: any) => {
      this.showQrCode = true;
      this.appUrl = environment['APP_URL'] + "/issue" +
        "?_oobid=" + this.localDataStorageService.getData("oobid") +
        "&credentialProviderLink=" + environment['DC_URL'] + "/issuance";
      if (msg instanceof MessageContainer) {
        if (msg.getMessages().length < msg.getTaskAmount()) {
          msg.setTaskAmount(msg.getMessages().length);
        }
        msg = JSON.stringify(msg, (key, value) => {
          if (value === undefined || value === null) {
            return undefined;
          }
          // Filtering null and undefined values from arrays
          if (Array.isArray(value)) {
            return value.filter((item) => item !== null && item !== undefined);
          }
          return value;
        });

        this.dataService.send(msg, environment['DC_URL'] + '/v2/initSession', true).then((response: any) => {
          if (response['type'] === "de.kaprion.icp.s2p.issueCredential.req") {
            this.showQrCode = false;
            this.isSEPA = true;
            let attach: any[] = []
            if (response['credentialApplication'] !== undefined) {
              if (response['credentialApplication']['verifiableCredential'] !== undefined) {
                attach = response['credentialApplication']['verifiableCredential'];
                attach.forEach((credential: any) => {
                  this.requestPresentation.forEach(requested => {
                    this.processReceivedVC(<VerifiableCredential1xModel>credential);
                  })
                });
                if (this.proofArray.length >= this.requestPresentation.length / 2) {
                  this.isIssuance = true;
                  this.statusOk = true;
                  //this.messageText = "Ihr Konto wird nun eröffnet.";
                  let finalCredential = this.csb
                    .id(id)
                    .addContext(credentialContext)
                    .addType(this.credentialType)
                    .issuanceDate()
                    .expirationDate(this.csb.getDateTime(true, 1))
                    .credentialSubject(this.credSubject)
                    .evidence(this.evidence)
                    .build();
                  this.localDataStorageService.setData("credentialArray", [finalCredential]);
                  this.localDataStorageService.setData("taskType", "cs.icp.split.issueCredential");
                  let finalMessage = this.createMessageService.prepareMessageShortenedVersion(Issuer.getIDIBankIssuer(), true, true, false).then(finalMsg => {
                    if (finalMsg instanceof MessageContainer) {
                      finalMsg = JSON.stringify(finalMsg, (key, value) => {
                        if (value === undefined || value === null) {
                          return undefined;
                        }
                        // Filtering null and undefined values from arrays
                        if (Array.isArray(value)) {
                          return value.filter((item) => item !== null && item !== undefined);
                        }
                        return value;
                      });
                      this.dataService.send(finalMsg, environment['DC_URL'] + '/v2/initSession').then((response: any) => {
                        this.messageText = "Ihr Nachweis wurde erfolgreich ausgestellt.";
                        this.messageArray.push(this.messageText);
                        if (response['ok']) {
                          this.statusOk = true;
                          this.isIssuance = false;
                        } else {
                          this.messageText = "Nachweise konnten nicht verarbeitet werden: Status " + response['status'];
                          this.messageArray.push(this.messageText);
                          this.statusOk = false;
                        }
                      });
                    }
                  })
                } else {
                  this.alertMessage = "Vorgelegte Nachweise erfüllen nicht die Anforderung zur Ausstellung des VDV-Tickets."
                  this.hintMessage = "Folgende Nachweise erfüllen nicht die Anforderungen: " + this.checkEntry();
                  this.messageType = MessageType.BackDialog;
                  this.blnShowDialogHandler = true;
                  this.statusOk = false;
                }
              } else {
                this.statusOk = false;
                this.messageText = "Es wurden keine Nachweise präsentiert. Folgende Nachweise müssen präsentiert werden: "
                this.messageArray.push(this.messageText);
                this.requestPresentation.forEach(requestedPresentation => {
                  this.messageText += requestedPresentation + "\n"
                });
              }
            } else {
              this.statusOk = false;
              this.messageText = "Es wurden keine Nachweise präsentiert. Folgende Nachweise müssen präsentiert werden: "
              this.messageArray.push(this.messageText);
              this.requestPresentation.forEach(requestedPresentation => {
                this.messageText += requestedPresentation + "\n"
              });
            }


          } else {
            this.messageText = "Nachweise konnten nicht verarbeitet werden: Status " + response['status'];
            this.messageArray.push(this.messageText);
            this.statusOk = false;
          }
          this.credentialArray = [];
          this.reset();
        }).catch((e) => {

          console.warn("Rejected: " + e);
        });
      }
    });
  }

/**
   * 
   * @returns entry that is not present in the result array of received credentials from presentation
   */
  checkEntry() {
    return this.requestPresentation.filter(entry => !this.proofArray.includes(entry));
  }

  reset() {
    this.proofArray = [];
    this.sessionId = this.localDataStorageService.getData("sessionId");
    this.buttonText = "Prozess starten";
    this.sepaForm.controls['creditInstitution'].setValue("");
    this.sepaForm.controls['givenName'].setValue("");
    this.sepaForm.controls['familyName'].setValue("");
    this.sepaForm.controls['iban'].setValue("");
    this.sepaForm.controls['bic'].setValue("");
    this.sepaForm.controls['repeatablePayment'].setValue("false");
    this.sepaForm.controls['intendedUse'].setValue("");
    this.sepaForm.controls['creditInstitution'].enable();
    this.sepaForm.controls['givenName'].enable();
    this.sepaForm.controls['familyName'].enable();
    this.sepaForm.controls['iban'].enable();
    this.sepaForm.controls['bic'].enable();
    this.sepaForm.controls['repeatablePayment'].enable();
    this.sepaForm.controls['intendedUse'].enable();
    this.isDisplayAddress = false;
    this.isDisplayAgeProof = false;
    this.isDisplayPerson = false;
    this.isDisplaySepa = false;
    this.isInput = true;
    this.credentialSubjects = [];
    this.credentialArray = [];
    this.presentationID = "";
    this.state = StateMachine.REQ_PRESENTATION;
    this.appUrl = "";
    this.showQrCode = false;
    try {
      const pic = document.querySelector('#pic');
      if (pic !== null) pic.remove();
    } catch (e) { }
    try {
      const table = document.querySelector('#table');
      if (table !== null) table.remove();
    } catch (e) { }
  }

  //abort-functionality | back-button
  onClickBack() {
    this.isDisplaySepa = !this.isDisplaySepa;
    this.isInput = true;
    this.sepaForm.controls['creditInstitution'].enable();
    this.sepaForm.controls['iban'].enable();
    this.sepaForm.controls['bic'].enable();
    this.sepaForm.controls['repeatablePayment'].enable();
    this.sepaForm.controls['intendedUse'].enable();
    this.buttonText = "Eingaben prüfen";
    this.state = StateMachine.REQ_PRESENTATION;
  }

  onClickCancel() {
    this.messageType = MessageType.OkCancelDialog;
    this.alertMessage = "Möchten Sie zur Credentialauswahl zurückkehren?";
    this.hintMessage = "Ihre Eingaben gehen dabei verloren.";
    this.blnShowDialogHandler = true;
  }
    /**
   * catches emitter from dialog-handler
   */
  cancelAction() {
    this.blnShowDialogHandler = false;
  }

  /**
 * catches emitter from dialog-handler
 */
  okAction() {
    this.blnShowDialogHandler = false;
  }

  fillForm() {
    this.sepaForm.controls['creditInstitution'].setValue("IDI Bank");
    this.sepaForm.controls['iban'].setValue(this.ibanGenService.generateIban());
    this.sepaForm.controls['bic'].setValue("IDI80376");
    this.sepaForm.controls['repeatablePayment'].setValue(false);
    this.sepaForm.controls['intendedUse'].setValue("Einzelfahrt Zahlung");
  }
}

enum StateMachine {
  REQ_PRESENTATION,
  INPUT_SEPA
}

