import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import { MessageType } from '../../ui/dialog-handler/dialog-handler.component';
import { LocalDataStorageService } from 'src/app/services/local-data-storage.service';

@Component({
  selector: 'app-vdv-ticket-v2',
  templateUrl: './vdv-ticket-v2.component.html',
  styleUrls: ['./vdv-ticket-v2.component.scss']
})
export class VDVTicketV2Component implements OnInit {

  showLoader: boolean = false;
  appUrl: string = "";
  showQrCode = false;
  alertMessage: string = "";
  hintMessage: string = "";
  blnShowDialogHandler: boolean = false;
  messageType: MessageType = MessageType.BackDialog;

  constructor(private dataService: DataService,
    private localDataStorageService: LocalDataStorageService
  ) {
  }

  ngOnInit() {
    this.showLoader = true;
    this.getQRCodeData();
  }

  /**
  * to get the data for qr-code
  */
  getQRCodeData() {
    let issuanceDate = this.getDateTime(true);
    let expirationDate = this.getDateTime(true, 1);
    let data = JSON.stringify({
      "issuanceDate": issuanceDate,
      "expirationDate": expirationDate,
      "credentialSubject": {
        "areaOfValidity": {
          "provider": 6060,
          "area": [
            10
          ]
        },
        "brand": 10014
      }
    });
    this.dataService.send(data, environment['PROCESSENGINE'] + '/sfc/startProcessSync?process=issueVcVdvTicket').then(response => {
      this.showLoader = false;
      if (response !== null
        && response.status !== undefined && response.status === 200
        && response.result !== undefined && response.result !== ""
      ) {
        this.appUrl = this.appUrl = environment['APP_URL'] + "/issue" +
        "?_oobid=" + response.result +
        "&credentialProviderLink=" + environment['DC_URL'] + "/issuance";
        this.localDataStorageService.setData("oobid", response.result);
        this.showQrCode = true;
      } else if ((response === null || response === "") || (response.status !== undefined && response.status !== 200)) {
        this.alertMessage = response.statusText;
        this.blnShowDialogHandler = true;
      }
    });
  }

  /**
  * catches emitter from dialog-handler
  */
  cancelAction() {
    this.blnShowDialogHandler = false;
  }

  /**
  * catches emitter from dialog-handler
  */
  okAction() {
    this.blnShowDialogHandler = false;
  }

  // to get date time ISO string
  getDateTime(isDateTime: boolean, yearsUntilExpiration: number = 0) {
    let date = new Date();
    if (isDateTime) {
      if (yearsUntilExpiration > 0) {
        date.setFullYear(date.getFullYear() + yearsUntilExpiration);
      }
      return date.toISOString();
    } else {
      if (yearsUntilExpiration > 0) {
        date.setFullYear(date.getFullYear() + yearsUntilExpiration);
      }
      return date.toISOString().split("T")[0];
    }
  }
}