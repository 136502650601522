import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, timeout } from 'rxjs';

export interface ErrorState {
  show: boolean,
  messageText: string,
  status: boolean
}

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  private readonly errorSubject = new Subject<ErrorState>();
  public errorMessage = this.errorSubject.asObservable();
  
  constructor(private readonly router: Router) { }

  handleMessage(message: ErrorState) {
    this.errorSubject.next(message);
  }
}
