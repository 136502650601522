

<header class="ticketbuy-heading">Fahrrad ausleihen</header>
<div class="main-wrapper container-fluid">
  <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3" *ngIf="this.proofArray.length === 0 && showQrCode === false">
    <div class="card border-0 card_shadow">
        <div class="card-body">
            <h4>Hier können Sie ein Fahrrad mittels Vorlage der folgenden Nachweise Ihrer IDealWallet-App ausleihen:</h4>
            <ul>
                <li *ngFor="let entry of requestPresentation">{{entry.type | getLang}}</li>
            </ul>
        </div>
    </div>
  </div>
  <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3" *ngIf="isIssuance">
    <div class="card border-0 card_shadow">
      <div class="card-body">
        <h3 style="text-align: center;">Ihr {{credentialType | getLang}} wird nun ausgestellt.
        </h3>
      </div>
    </div>
  </div>

<div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3" *ngIf="this.proofArray.length > 0">
  <div class="card border-0 card_shadow">
      <div class="card-body">
          <h3 style="text-align: center;">Das Fahrrad {{enabledBike}} ist für Sie freigeschaltet.
          </h3>

      </div>
  </div>
</div>
<app-presentation *ngIf="proofArray.length >=1"
  [proofArray]="proofArray"></app-presentation>
<app-showqrcode *ngIf="showQrCode" [verificationUrl]="appUrl" class="vh-73"></app-showqrcode>
<div class="flex-wrapper" style="margin: auto 0;">
  <ng-container *ngIf="true">
    <button *ngIf="!showQrCode" (click)="issueCredential()" [ngClass]="showQrCode ? 'link-box-white vh40': 'link-box-white'"
      name="next">{{buttonText}}
    </button>
    <button *ngIf="!statusOk && requestPresentation && !showQrCode && (step === 0)" type="button" (click)="onClickCancel()" class="link-box-white">Abbrechen</button>
  </ng-container>
</div>
</div>
<app-dialog-handler class="dialog-handler" *ngIf="blnShowDialogHandler && messageType!==undefined" [messageType]="messageType" (CancelAction)="cancelAction()" (OkAction)="okAction()" [headingMessageText]="alertMessage" [additionalMessageText]="hintMessage"></app-dialog-handler>
<ng-container *ngFor="let message of messageArray">
  <app-toaster *ngIf="message.length > 0" [messageText]="message" [status]="statusOk"></app-toaster>
</ng-container>
<!-- Footer -->
<!--<app-footer [footerButtons]="footerButtons" [selectedTicket]="selectedTicket"></app-footer>-->